import { ChangeEvent, useEffect, useRef, useState } from 'react'
import Logo from '../../../Logo';
import { SalesPerSellerView } from './SalesPerSeller/SalesPerSellerView';
import { Alert, Box, Button, Tab, Tabs } from '@mui/material';
import { SalesPerBranchView } from './SalesPerBranch/SalesPerBranchView';
import { fetchMarginFamilyMonth, fetchRankingProducts, fetchSalesBranchMonth, fetchSalesBrandMonth, fetchSalesClientMonth, fetchSalesFamilyMonth, fetchSalesSellerMonth, fetchSalesSupplierMonth } from '../../../../services/statisticsService';
import { SalesPerClientView } from './SalesPerClient/SalesPerClientView';
import { getRowsToArrayProducts } from '../../../../utils/readExcel';
import { uploadProductListFileXlss } from '../../../../services/productService';
import { useAppSelector } from '../../../../hooks/storeHooks';
import store from '../../../../store';
import { ProductStatisticActions } from '../../../../reducers/ProductStatisticsReducer';
import { AccessibilityNew, EmojiEvents, FamilyRestroom, LocalShipping, Percent, Person, QrCode, QrCode2, Store } from '@mui/icons-material';
import { SalesPerFamilyView } from './SalesPerFamily/SalesPerFamilyView';
import { SalesPerBrandView } from './SalesPerBrand/SalesPerBrandView';
import { SalesPerSupplierView } from './SalesPerSupplier/SalesPerSupplierView';
import { ProductRankingView } from './ProductRanking/ProductRankingView';
import { MarginsView } from './Margins/MarginsView';

export const StatisticsView = () => {

    const [tab, setTab] = useState<
        "Ventas por vendedor" |
        "Ventas por Sucural" |
        "Ventas por Cliente" |
        "Ventas por Proveedor" |
        "Ventas por Familia" |
        "Ventas por Marca" |
        "Margenes" |
        "Ranking SKU"
    >("Ventas por vendedor");
    const { isLoading } = useAppSelector((s: any) => s.statisticsReducer);
    const { errorMsg, success } = useAppSelector((s: any) => s.productStatisticReducer);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const effectRan = useRef(true);
    function handleUpload(
        e: ChangeEvent<HTMLInputElement>
    ): void {
        const file = e.target.files![0];
        const rows = getRowsToArrayProducts(file)
        rows.then((rows: any[]) => {
            const upload = uploadProductListFileXlss(rows)
            e.target.value = "";
            upload.then(() => {
                setShowSuccessAlert(true);
            });
            upload.catch((err) => {
                if (err instanceof Error) {
                    store.dispatch({
                        type: ProductStatisticActions.UpdateErrorMsg,
                        payload: err.message
                    });
                    setErrorAlert(true);
                }
                e.target.value = "";
            });
        })
        rows.catch((err: any) => {
            store.dispatch({
                type: ProductStatisticActions.UpdateErrorMsg,
                payload: err
            })
            setErrorAlert(true);
            e.target.value = "";
        })
    }
    useEffect(() => {
        if (effectRan.current) {
            fetchSalesSellerMonth();
            return () => {
                effectRan.current = false;
            }
        }
    }, [])
    useEffect(() => {
        if (errorMsg !== "") {
            setErrorAlert(true);
        }
    }, [errorMsg]);
    useEffect(() => {
        if (success) {
            setShowSuccessAlert(true);
        }
    }, [success]);

    return (
        <div className="p-5 h-full overflow-auto">
            <Logo />
            <div className="text-xl mb-3">Estadísticas</div>
            <div className=" mt-2 mb-3 flex justify-end pr-7 space-x-2">
                <input
                    accept=".xls, .xlsx"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => handleUpload(e)}
                />
                <label htmlFor="contained-button-file">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        component="span"
                    >
                        Carga Masiva Productos
                    </Button>
                </label>
            </div>
            <div className="h-full flex flex-col overflow-auto">
                <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
                    <Tabs
                        value={tab}
                        onChange={(_, v) => {
                            setTab(v as
                                "Ventas por vendedor" |
                                "Ventas por Sucural" |
                                "Ventas por Cliente" |
                                "Ventas por Proveedor" |
                                "Ventas por Familia" |
                                "Ventas por Marca" |
                                "Margenes" |
                                "Ranking SKU");
                        }}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Ventas por vendedor" value={"Ventas por vendedor"} icon={<AccessibilityNew />} onClick={async () => await fetchSalesSellerMonth()} disabled={isLoading} />
                        <Tab label="Ventas por Sucural" value={"Ventas por Sucural"} icon={<Store />} onClick={async () => await fetchSalesBranchMonth()} disabled={isLoading} />
                        <Tab label="Ventas por Cliente" value={"Ventas por Cliente"} icon={<Person />} onClick={async () => await fetchSalesClientMonth()} disabled={isLoading} />
                        <Tab label="Ventas por Proveedor" value={"Ventas por Proveedor"} icon={<LocalShipping />} onClick={async () => await fetchSalesSupplierMonth()} disabled={isLoading} />
                        <Tab label="Ventas por Familia" value={"Ventas por Familia"} icon={<FamilyRestroom />} onClick={async () => await fetchSalesFamilyMonth()} disabled={isLoading} />
                        <Tab label="Ventas por Marca" value={"Ventas por Marca"} icon={<QrCode2 />} onClick={async () => await fetchSalesBrandMonth()} disabled={isLoading} />
                        <Tab label="Margenes" value={"Margenes"} icon={<Percent />} onClick={async () => await fetchMarginFamilyMonth()} disabled={isLoading} />
                        <Tab label="Ranking SKU" value={"Ranking SKU"} icon={<EmojiEvents />} onClick={async () => await fetchRankingProducts()} disabled={isLoading} />
                    </Tabs>
                </Box>
                {errorAlert && (
                    <Alert
                        severity="error"
                        onClose={() => {
                            setErrorAlert(false);
                        }}
                    >
                        {errorMsg !== "" ? errorMsg : "Hay un error en las filas"}{" "}
                    </Alert>
                )}
                {showSuccessAlert && (
                    <Alert
                        severity="success"
                        onClose={() => {
                            setShowSuccessAlert(false);
                        }}
                    >
                        Se han actualizado los productos correctamente
                    </Alert>
                )}

                <div className="flex-1 overflow-auto" style={{ paddingTop: '5px' }}>
                    {tab === "Ventas por vendedor" && <SalesPerSellerView />}
                    {tab === "Ventas por Sucural" && <SalesPerBranchView />}
                    {tab === "Ventas por Cliente" && <SalesPerClientView />}
                    {tab === "Ventas por Proveedor" && <SalesPerSupplierView />}
                    {tab === "Ventas por Familia" && <SalesPerFamilyView />}
                    {tab === "Ventas por Marca" && <SalesPerBrandView />}
                    {tab === "Margenes" && <MarginsView />}
                    {tab === "Ranking SKU" && <ProductRankingView />}

                </div>
            </div>
        </div>
    );
};

export default StatisticsView;