import React from 'react';
import { DeletedOrdersViewTypist } from '../../Common/Order/DeletedOrdersView';
interface Props {
  deletedOrders: Order[];
}

const DeletedOrdersView: React.FC<Props> = ({ deletedOrders }) => {
  return <DeletedOrdersViewTypist deletedOrders={deletedOrders} />;
};

export default DeletedOrdersView;
