import ProductFileList from "../types/ProductFileList";

interface State {
    products: ProductFileList[];
    isLoading: boolean;
    error: boolean;
    success: boolean;
    msg: string;
}

const initialState: State = {
    products: [],
    isLoading: false,
    error: false,
    success: false,
    msg: ""
};

export enum ProductListFileActions {
    RequestFiles = "REQUEST_FILES",
    ReceiveFiles = "RECEIVE_FILES",
    UploadFile = "UPLOAD_FILE",
    UploadFileSuccess = "UPLOAD_FILE_SUCCESS",
    DeleteFile = "DELETE_FILE",
    DeleteFileSuccess = "DELETE_FILE_SUCCESS",
    SetError = "SET_ERROR",
    ClearError = "CLEAR_ERROR",
    SetSuccess = "SET_SUCCESS",
    ClearSuccess = "CLEAR_SUCCESS",
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: ProductListFileActions; payload: any }
): State => {
    switch (type) {
        case ProductListFileActions.RequestFiles:
            return {
                ...state,
                isLoading: true,
            };
        case ProductListFileActions.ReceiveFiles:
            return {
                ...state,
                products: payload,
                isLoading: false
            };

        case ProductListFileActions.UploadFile:
            return {
                ...state,
                isLoading: true,
            };
        case ProductListFileActions.UploadFileSuccess:
            return {
                ...state,
                isLoading: false,
            };

        case ProductListFileActions.DeleteFile:
            return {
                ...state,
                isLoading: true,
            };
        case ProductListFileActions.DeleteFileSuccess:
            return {
                ...state,
                isLoading: false,
            };
        case ProductListFileActions.SetError:
            return {
                ...state,
                error: true,
                msg: payload
            }
        case ProductListFileActions.ClearError:
            return {
                ...state,
                error: false,
                msg: ""
            }
        case ProductListFileActions.SetSuccess:
            return {
                ...state,
                success: true,
                msg: payload
            }
        case ProductListFileActions.ClearSuccess:
            return {
                ...state,
                success: false,
                msg: ""
            }

        default:
            return state;
    }
};

export default reducer;