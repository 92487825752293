interface State {
    isLoading: boolean;
    errorMsg: string;
    success: boolean;
}

const initialState: State = {
    isLoading: false,
    errorMsg: "",
    success: false,
}

export enum ProductStatisticActions {
    SendProductStatistics = "SEND_PRODUCT_STATISTICS",
    ReceiveErrorsProductStatistics = "RECEIVE_ERRORS_PRODUCT_STATISTICS",
    UpdateErrorMsg = "UPDATE_ERROR_MSJ",
    UpdateSuccess = "UPDATE_SUCCESS"
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: ProductStatisticActions, payload: any }
): State => {
    switch (type) {
        case ProductStatisticActions.SendProductStatistics:
            return { ...state, isLoading: true, success: false, errorMsg: "" };
        case ProductStatisticActions.ReceiveErrorsProductStatistics:
            return { ...state, isLoading: false, success: true, errorMsg: "" };
        case ProductStatisticActions.UpdateErrorMsg:
            return { ...state, isLoading: false, success: false, errorMsg: payload };
        case ProductStatisticActions.UpdateSuccess:
            return { ...state, isLoading: false, success: false, errorMsg: "" };
        default:
            return state;
    }
}

export default reducer;