import React from 'react'
import { SalesPerBranch } from '../../../../../types/Statistics'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';



const calculateTotals = (data: SalesPerBranch[], dateSelected: string[]) => {
    return data.map(sucursal => {
        const totalVentas = dateSelected.map(month => {
            if (sucursal.sales[month] === undefined) {
                return 0;
            }
            return sucursal.sales[month] as number;
        }).reduce((acc, curr) => acc + curr, 0);
        return {
            name: sucursal.branch,
            value: totalVentas
        };
    });
}

export const SalesChart: React.FC<{ data: SalesPerBranch[], dateSelected: string[] }> = ({ data, dateSelected }) => {
    const totales = calculateTotals(data, dateSelected);
    const totalAcumulado = totales.reduce((acc, current) => acc + (current.value as number), 0);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    return (
        <>
            <ResponsiveContainer width="100%" height={600}>
                <PieChart >
                    <Pie
                        dataKey="value"
                        data={totales}
                        fill="#8884d8"
                        label={({ value, name, percent }) => {
                            if (value === 0) {
                                return '';
                            }
                            return `$${formatChileanLocale(value as number)} (${(percent * 100).toFixed(2)}%)`;
                        }}

                    >
                        {
                            totales.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                    </Pie>
                    <Tooltip formatter={(value) =>
                        formatChileanLocale(value as number)
                    } />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <strong>Total acumulado:</strong> ${formatChileanLocale(totalAcumulado)}
            </div>
        </>
    )
}
