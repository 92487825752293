interface State {
  customers: Customer[];
  isLoading: boolean;
  errorCustomers: ErrorCustomer[];
  success: boolean;
  errorMsg: string;
}

const initialState: State = {
  customers: [],
  isLoading: false,
  errorCustomers: [],
  success: false,
  errorMsg: "",
};

export enum CustomerActions {
  RequestCustomers = "REQUEST_CUSTOMERS",
  ReceiveCustomers = "RECEIVE_CUSTOMERS",
  AddCustomers = "ADD_CUSTOMERS",
  UpdateErrors = "UPDATE_ERRORS",
  UpdateErrorMsg = "UPDATE_ERROR_MSJ",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: CustomerActions; payload: any }
): State => {
  switch (type) {
    case CustomerActions.RequestCustomers: //hago el llamado para que la api me traiga los producots
      return {
        ...state,
        isLoading: true,
        success: false,
        errorMsg: "",
        errorCustomers: [],
      };
    case CustomerActions.ReceiveCustomers: //recibo el arreglo de productos
      return { ...state, customers: payload, isLoading: false };
    case CustomerActions.AddCustomers: //agrego un producto
      return {
        ...state,
        isLoading: false,
        success: true,
        customers: [
          ...state.customers.filter((p) => {
            return !payload.some((f: any) => {
              return f._id === p._id;
            });
          }),
          ...payload,
        ],
      };
    case CustomerActions.UpdateErrors:
      return {
        ...state,
        errorCustomers: payload,
        isLoading: false,
      };
    case CustomerActions.UpdateErrorMsg:
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
