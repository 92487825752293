import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import { useForm } from "../../../../hooks/useForm";
import { useEffect, useState } from "react";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    onSubmit: (values: { username: string; password: string }) => void;
    typist: Typist | null;
    setTypist: (typist: Typist | null) => void;
}

const EditPasswordModal: React.FC<Props> = ({
    open,
    onClose,
    typist,
    setTypist,
    onSubmit,
}) => {
    const initialValues = {
        password: "",
        username: "",
    };
    const [type, setType] = useState("password"); //para mostrar el password como texto o como puntos
    const [values, setValues, handleChange] = useForm(initialValues);
    useEffect(() => {
        if (typist) {
            setValues({
                ...values,
                username: typist.username,
            });
        } else {
            setValues(initialValues);
        }
    }, [typist]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            onTransitionEnd={() => !open && setValues(initialValues)}
        >
            <DialogTitle>Cambiar contraseña</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <div>{values.username}</div>
                    <TextField
                        id="password"
                        label="Contraseña"
                        name="password"
                        type={type}
                        value={values.password}
                        onChange={handleChange}
                    />
                    <Button
                        onClick={
                            () =>
                                type === "password" ? setType("text") : setType("password") //si el type es password, lo cambio a text, y si es text, lo cambio a password
                        }
                    >
                        {type === "password" ? "Mostrar" : "Ocultar"} contraseña
                    </Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setTypist(null);
                        onClose();
                    }}
                >
                    Cancelar
                </Button>
                <Button variant="contained" onClick={() => onSubmit(values)}>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default EditPasswordModal;
