import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { Alert, Button, Card } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { getRowsToArray } from "../../../../utils/readExcel";
import { updateFullCatalog } from "../../../../services/productService";
import store from "../../../../store";
import { ProductActions } from "../../../../reducers/ProductReducer";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";

import { fetchCustomers } from "../../../../services/customerService";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const CustomerReportView: React.FC = () => {
  const {
    customers,
    isLoading,
    errorCustomers,
  } = useAppSelector((s: any) => s.customerReducer);
  const [errorAlert, setErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [totalMonths, setTotalMonths] = useState({
    total: "",
    month1sales: 0,
    month2sales: 0,
    month3sales: 0,
    month4sales: 0,
    month5sales: 0,
    month6sales: 0,
  })

  useEffect(() => {
    let newTotalMonths = {
      total: "",
      month1sales: 0,
      month2sales: 0,
      month3sales: 0,
      month4sales: 0,
      month5sales: 0,
      month6sales: 0,
    }
    for (const customer of customers) {
      newTotalMonths.month1sales += customer.month1sales;
      newTotalMonths.month2sales += customer.month2sales;
      newTotalMonths.month3sales += customer.month3sales;
      newTotalMonths.month4sales += customer.month4sales;
      newTotalMonths.month5sales += customer.month5sales;
      newTotalMonths.month6sales += customer.month6sales;
    }

    setTotalMonths(newTotalMonths);
  }, [customers])

  let month1 = new Date();
  let month2 = new Date();
  month2.setMonth(month1.getMonth() - 1);
  let month3 = new Date();
  month3.setMonth(month1.getMonth() - 2);
  let month4 = new Date();
  month4.setMonth(month1.getMonth() - 3);
  let month5 = new Date();
  month5.setMonth(month1.getMonth() - 4);
  let month6 = new Date();
  month6.setMonth(month1.getMonth() - 5);

  return (
    <div className="h-full overflow-auto">
      <Card>
        <DataGrid
          sx={{ padding: 2 }}
          autoHeight={true}
          columns={[
            {
              field: "total",
              headerName: "Total",
              width: 450,
            },
            {
              field: "month6sales",
              headerName: month6.toLocaleDateString("es-ES", {
                weekday: undefined,
                year: undefined,
                month: "long",
                day: undefined,
              }),
              width: 100,
              renderCell(params: GridRenderCellParams) {
                return params.row.month6sales.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
            {
              field: "month5sales",
              headerName: month5.toLocaleDateString("es-ES", {
                weekday: undefined,
                year: undefined,
                month: "long",
                day: undefined,
              }),
              width: 100,
              renderCell(params: GridRenderCellParams) {
                return params.row.month5sales.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
            {
              field: "month4sales",
              headerName: month4.toLocaleDateString("es-ES", {
                weekday: undefined,
                year: undefined,
                month: "long",
                day: undefined,
              }),
              width: 100,
              renderCell(params: GridRenderCellParams) {
                return params.row.month4sales.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
            {
              field: "month3sales",
              headerName: month3.toLocaleDateString("es-ES", {
                weekday: undefined,
                year: undefined,
                month: "long",
                day: undefined,
              }),
              width: 100,
              renderCell(params: GridRenderCellParams) {
                return params.row.month3sales.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
            {
              field: "month2sales",
              headerName: month2.toLocaleDateString("es-ES", {
                weekday: undefined,
                year: undefined,
                month: "long",
                day: undefined,
              }),
              width: 100,
              renderCell(params: GridRenderCellParams) {
                return params.row.month2sales.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },

            {
              field: "month1sales",
              headerName: month1.toLocaleDateString("es-ES", {
                weekday: undefined,
                year: undefined,
                month: "long",
                day: undefined,
              }),
              width: 100,
              renderCell(params: GridRenderCellParams) {
                return params.row.month1sales.toLocaleString("es-CL", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
          ]
          }
          rows={[totalMonths]}
          getRowId={(row) => row.total}
          pageSize={1}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          disableColumnMenu
        />
        <DataGrid
          sx={{ padding: 2 }}
          autoHeight={true}
          columns={
            [
              {
                field: "name",
                headerName: "Cliente",
                width: 450,
              },
              {
                field: "month6sales",
                headerName: month6.toLocaleDateString("es-ES", {
                  weekday: undefined,
                  year: undefined,
                  month: "long",
                  day: undefined,
                }),
                width: 100,
                renderCell(params: GridRenderCellParams) {
                  return params.row.month6sales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },
              {
                field: "month5sales",
                headerName: month5.toLocaleDateString("es-ES", {
                  weekday: undefined,
                  year: undefined,
                  month: "long",
                  day: undefined,
                }),
                width: 100,
                renderCell(params: GridRenderCellParams) {
                  return params.row.month5sales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },
              {
                field: "month4sales",
                headerName: month4.toLocaleDateString("es-ES", {
                  weekday: undefined,
                  year: undefined,
                  month: "long",
                  day: undefined,
                }),
                width: 100,
                renderCell(params: GridRenderCellParams) {
                  return params.row.month4sales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },
              {
                field: "month3sales",
                headerName: month3.toLocaleDateString("es-ES", {
                  weekday: undefined,
                  year: undefined,
                  month: "long",
                  day: undefined,
                }),
                width: 100,
                renderCell(params: GridRenderCellParams) {
                  return params.row.month3sales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },
              {
                field: "month2sales",
                headerName: month2.toLocaleDateString("es-ES", {
                  weekday: undefined,
                  year: undefined,
                  month: "long",
                  day: undefined,
                }),
                width: 100,
                renderCell(params: GridRenderCellParams) {
                  return params.row.month2sales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },

              {
                field: "month1sales",
                headerName: month1.toLocaleDateString("es-ES", {
                  weekday: undefined,
                  year: undefined,
                  month: "long",
                  day: undefined,
                }),
                width: 100,
                renderCell(params: GridRenderCellParams) {
                  return params.row.month1sales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  });
                },
              },
            ]
          }
          rows={customers}
          pageSize={20}
          getRowId={(row) => row.rut}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          components={{ Toolbar: CustomToolbar }}
        />
      </Card>
    </div>
  );
};

export default CustomerReportView;
