import * as React from 'react';
import './VentasDataGrid.css';
import {
  GridRow,
  SalesPerBrand,
  SalesPerDate,
} from '../../../../../types/Statistics';
import {
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';

const transformData = (data: SalesPerBrand[]): GridRow[] => {
  return data.map((item, index) => ({
    id: index,
    brand: item.brand,
    ...item.sales,
  }));
};

const getAllColumns = (data: SalesPerBrand[]): string[] => {
  const allColumns: Set<string> = new Set();
  data.forEach((item) => {
    Object.keys(item.sales).forEach((column) => {
      allColumns.add(column);
      allColumns.add(`${column}-participation`);
    });
  });
  return Array.from(allColumns).sort();
};

const SalesTable: React.FC<{
  data: SalesPerBrand[];
  filterText: string;
  selectedBrands: any;
  handleBrandSelection: (brand: string) => void;

  dateSelected: string[];
  setDateSelected: React.Dispatch<React.SetStateAction<string[]>>;
    totals: SalesPerDate;
}> = ({ data, filterText, selectedBrands, handleBrandSelection, dateSelected, setDateSelected, totals }) => {

  const rows = transformData(data);
  const allColumns = getAllColumns(data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortColumn, setSortColumn] = useState(''); // Estado para rastrear la columna de ordenación
  const [sortDescending, setSortDescending] = useState(false); // Estado para rastrear la dirección de ordenación
  const [filteredRows, setFilteredRows] = useState<GridRow[]>(
    rows.filter((row) =>
      row.brand?.toLowerCase().includes(filterText.toLowerCase())
    )
  ); // Estado para rastrear las filas filtradas y ordenadas

  // Función para filtrar y ordenar las filas según la paginación
  const filterAndSortRows = () => {
    // Filtrar las filas según el texto de filtro
    let filtered = rows.filter((row) =>
      row.brand?.toLowerCase().includes(filterText.toLowerCase())
    );

    // Ordenar las filas si se ha seleccionado una columna de ordenación
    if (sortColumn) {
      filtered = [...filtered].sort((a, b) => {
        const aValue: any =
          typeof a[sortColumn] === 'number' ? a[sortColumn] : 0;
        const bValue: any =
          typeof b[sortColumn] === 'number' ? b[sortColumn] : 0;

        return sortDescending ? bValue - aValue : aValue - bValue;
      });
    }

    // Actualizar el estado con las filas filtradas y ordenadas
    setFilteredRows(filtered);
  };

  // Llamamos a filterAndSortRows cada vez que cambian el filtro, la columna de ordenación, la página o la cantidad de filas por página
  useEffect(() => {
    filterAndSortRows();
  }, [filterText, sortColumn, sortDescending, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Función para manejar el clic en el encabezado de la columna
  const handleColumnHeaderClick = (column: string) => {
    if (sortColumn === column) {
      // Si ya está ordenado por esta columna, cambiar la dirección de ordenación
      setSortDescending(!sortDescending);
    } else {
      // Si es una columna diferente, ordenar ascendentemente
      setSortColumn(column);
      setSortDescending(false);
      setPage(0);
    }
  };

  return (
    <div>
      <Card style={{ border: 'solid', borderColor: 'grey', maxWidth: 1300 }}>
        <div>
          <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: 600 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ position: 'sticky', left: 0, zIndex: 1 }}
                  ></TableCell>
                  <TableCell
                    className='header-vendedor'
                    style={{ position: 'sticky', left: 50, zIndex: 1 }}
                  >
                    Marca
                  </TableCell>
                  {allColumns.map((column) => (
                    <TableCell key={column}>
                      <Checkbox
                        checked={dateSelected.includes(column)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onClick={() => {
                          if (dateSelected.includes(column)) {
                            setDateSelected([
                              ...dateSelected.filter((item) => item !== column),
                            ]);
                          } else {
                            setDateSelected([...dateSelected, column]);
                          }
                        }}
                      ></Checkbox>
                      {column}
                      {!column.includes('participation') && (
                        <IconButton
                          size='small'
                          onClick={() => handleColumnHeaderClick(column)}
                        >
                          <FilterAltIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        className='cell-columna'
                        style={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: 'white',
                        }}
                      >
                        <Checkbox
                          checked={selectedBrands.includes(row.brand)}
                          onChange={() => handleBrandSelection(row.brand!)}
                        />
                      </TableCell>
                      <TableCell
                        className='cell-columna'
                        style={{
                          position: 'sticky',
                          left: 50,
                          zIndex: 1,
                          backgroundColor: 'white',
                        }}
                      >
                        {row.brand}
                      </TableCell>
                      {allColumns.map((column) => {
                        if (column.endsWith('-participation')) {
                          const month = column.replace('-participation', '');
                          const participation = row[month]
                            ? (
                              (Number(row[month]) / Number(totals[month])) *
                              100
                            ).toFixed(2)
                            : '-';

                          return (
                            <TableCell
                              className='cell-columna'
                              style={{ color: 'red' }}
                              key={column}
                            >
                              {participation !== '-'
                                ? `${participation}%`
                                : '-'}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell className='cell-columna' key={column}>
                              {typeof row[column] === 'number'
                                ? `$${formatChileanLocale(
                                  row[column] as number
                                )}`
                                : '-'}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    className='cell-columna'
                    style={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      bottom: 0,
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className='cell-columna'
                    style={{
                      position: 'sticky',
                      left: 50,
                      zIndex: 1,
                      backgroundColor: 'white',
                      bottom: 0,
                    }}
                  >
                    Totales
                  </TableCell>
                  {allColumns.map((column) => {
                    if (column.endsWith('-participation')) {
                      return (
                        <TableCell
                          className='cell-columna'
                          key={column}
                          style={{
                            position: 'sticky',
                            left: 50,
                            zIndex: 1,
                            backgroundColor: 'white',
                            bottom: 0,
                            color: 'red',
                          }}
                        >
                          100%
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          className='cell-columna'
                          key={column}
                          style={{
                            position: 'sticky',
                            left: 50,
                            zIndex: 1,
                            backgroundColor: 'white',
                            bottom: 0,
                          }}
                        >{`$${formatChileanLocale(totals[column])}`}</TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Card>
    </div>
  );
};

export default SalesTable;
