interface State {
    statistics: any;
    isLoading: boolean;
}

const initialState: State = {
    statistics: [],
    isLoading: false,
}

export enum StatisticsActions {
    RequestStatistics = "REQUEST_STATISTICS",
    ReceiveStatistics = "RECEIVE_STATISTICS",
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: StatisticsActions; payload: any }
): State => {
    switch (type) {
        case StatisticsActions.RequestStatistics:
            return { ...state, isLoading: true };
        case StatisticsActions.ReceiveStatistics:
            return { ...state, statistics: payload, isLoading: false };
        default:
            return state;
    }
};
export default reducer;
