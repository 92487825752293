import {
  AppBar,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { mdiLogoutVariant, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import { useState } from "react";
import {
  ManagerRoutes,
  SupervisorRoutes,
  TypistRoutes,
  Routes,
} from "../../../../config/Routes";
import { AuthActions } from "../../../../reducers/AuthReducer";
import store from "../../../../store";

interface MenuProps {
  role: string;
}

const Menu: React.FC<MenuProps> = ({ role }) => {
  const history = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const route = history.pathname;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return store.dispatch({ type: AuthActions.DelSession, payload: null });
  };

  const getMenuItems = () => {
    switch (role) {
      case "manager":
        return (
          <>
            <Link to={Routes.Home}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === Routes.Home ? "text-archiLightBlue" : ""
                }`}
              >
                Inicio
              </div>
            </Link>
            <Link to={ManagerRoutes.Orders}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Orders ? "text-archiLightBlue" : ""
                }`}
              >
                Ordenes
              </div>
            </Link>
            <Link to={ManagerRoutes.Quotations}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Quotations
                    ? "text-archiLightBlue"
                    : ""
                }`}
              >
                Cotizaciones
              </div>
            </Link>
            <Link to={ManagerRoutes.Sellers}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Sellers ? "text-archiLightBlue" : ""
                }`}
              >
                Vendedores
              </div>
            </Link>
            <Link to={ManagerRoutes.Supervisors}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Supervisors
                    ? "text-archiLightBlue"
                    : ""
                }`}
              >
                Supervisores
              </div>
            </Link>
            <Link to={ManagerRoutes.Typists}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Typists ? "text-archiLightBlue" : ""
                }`}
              >
                Digitadores
              </div>
            </Link>
            <Link to={ManagerRoutes.Products}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Products ? "text-archiLightBlue" : ""
                }`}
              >
                Lista de Productos
              </div>
            </Link>
            <Link to={ManagerRoutes.ProductList}>
              <div
                className={`${
                  route === ManagerRoutes.ProductList
                    ? "text-archiLightBlue"
                    : ""
                }`}
              >
                Catálogo
              </div>
            </Link>
            <Link to={ManagerRoutes.Clients}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Clients ? "text-archiLightBlue" : ""
                }`}
              >
                Clientes
              </div>
            </Link>
            <Link to={ManagerRoutes.Reports}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Reports ? "text-archiLightBlue" : ""
                }`}
              >
                Reportes
              </div>
            </Link>
            <Link to={ManagerRoutes.Statistics}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Statistics
                    ? "text-archiLightBlue"
                    : ""
                }`}
              >
                Estadísticas
              </div>
            </Link>
            <Link to={ManagerRoutes.Invoices}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Invoices ? "text-archiLightBlue" : ""
                }`}
              >
                Facturas
              </div>
            </Link>
          </>
        );
      case "supervisor":
        return (
          <>
            <Link to={Routes.Home}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === Routes.Home ? "text-archiLightBlue" : ""
                }`}
              >
                Inicio
              </div>
            </Link>
            <Link to={SupervisorRoutes.Reports}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === SupervisorRoutes.Reports
                    ? "text-archiLightBlue"
                    : ""
                }`}
              >
                Reportería
              </div>
            </Link>
          </>
        );
      case "typist":
        return (
          <>
            <Link to={TypistRoutes.Orders}>
              <div
                onClick={() => setIsNavOpen(false)}
                className={`${
                  route === ManagerRoutes.Orders ? "text-archiLightBlue" : ""
                }`}
              >
                Ordenes
              </div>
            </Link>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className="flex justify-between">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsNavOpen(true)}
          >
            <Icon path={mdiMenu} size={1} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        PaperProps={{ sx: { backgroundColor: "#003755", color: "white" } }}
        open={isNavOpen}
        onClose={() => setIsNavOpen(false)}
      >
        <div className="flex flex-col space-y-4 p-4">
          {getMenuItems()}
          <div
            onClick={handleLogout}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <ListItemText>Cerrar sesión</ListItemText>
            <ListItemIcon
              sx={{ color: "inherit", minWidth: "0", marginRight: "8px" }}
            >
              <Icon className="w-7" path={mdiLogoutVariant}></Icon>
            </ListItemIcon>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
