interface State {
    trucks: Truck[];
    isLoading: boolean;
}

const initialState: State = {
    trucks: [],
    isLoading: false,
};

export enum TruckActions {
    RequestTrucks = "REQUEST_TRUCKS",
    ReceiveTrucks = "RECEIVE_TRUCKS",
    AddTruck = "ADD_TRUCK",
    DeleteTruck = "DELETE_TRUCK",
}
// el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
const reducer = (
    state: State = initialState,
    { type, payload }: { type: TruckActions; payload: any }
): State => {
    switch (type) {
        case TruckActions.RequestTrucks: //hago el llamado para que la api me traiga los producots
            console.log("request trucks")
            return { ...state, isLoading: true };
        case TruckActions.ReceiveTrucks: //recibo el arreglo de productos
            console.log("saving trucks")
            return { ...state, trucks: payload, isLoading: false };
        case TruckActions.AddTruck: //agrego un producto
            return {
                ...state,
                trucks: [
                    ...state.trucks.filter((p) => p._id !== payload._id),
                    payload,
                ],
            };
        case TruckActions.DeleteTruck:
            return {
                ...state,
                trucks: state.trucks.filter((p) => p._id !== payload._id),
            };
        default:
            return state;
    }
};

export default reducer;
