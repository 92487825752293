import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    TextField,
    Grid,
    Typography,
    Chip,
} from "@mui/material";
import Icon from "@mdi/react";
import { ArrowCircleRight, ArrowCircleRightRounded, ArrowRight, ArrowRightAlt, Cancel, Photo, PhotoCamera, UploadFile } from "@mui/icons-material";
import { mdiCircleSlice1 } from "@mdi/js";
import ProductFileList from "../../../../types/ProductFileList";
import { fetchProductListFiles, uploadProductListFile } from "../../../../services/productService";
import store from "../../../../store";
import { ProductListFileActions } from "../../../../reducers/ProductListFileReducer";


interface UpdateFileModalProps {
    open: boolean;
    file: ProductFileList;
    handleClose: () => void;
}


export const UpdateFileModal: React.FC<UpdateFileModalProps> = ({
    open,
    handleClose,
    file,
}) => {

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
    const handleButtonClick = () => {
        setTimeout(() => {
            store.dispatch({ type: ProductListFileActions.ClearError, payload: {} });
            store.dispatch({ type: ProductListFileActions.ClearSuccess, payload: {} });
        }, 3000);
    }

    const fileSelectedHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleAssign = async () => {
        setIsUploadingFile(true);
        await uploadProductListFile(selectedFile, file.Key.split("/")[1]);
        setSelectedFile(null);
        setIsUploadingFile(false);
        handleClose();
        await fetchProductListFiles();
        handleButtonClick();
    };

    return (
        <Dialog open={open} onClose={() => {
            if (!isUploadingFile) handleClose()
            else return
        }}
            sx={{
                "& .MuiDialog-paper": {
                    width: "30rem",
                },
            }}
        >
            <>
                <DialogTitle>
                    Recambio de archivo
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            marginBottom: "1rem",
                        }}
                    >
                        Selecciona un archivo para reemplazar el actual.
                    </DialogContentText>
                    <Button
                        variant="outlined"
                        component="label"
                        sx={{
                            marginBottom: "1rem",
                        }}
                        endIcon={<UploadFile />}
                    >
                        Cargar Archivo
                        <input
                            type="file"
                            accept="application/pdf"
                            hidden
                            onChange={fileSelectedHandler}
                        />
                    </Button>
                    <Grid container direction="row">
                        <Chip
                            label={file.Key.split("/")[1]}
                            color="warning"
                            sx={{
                                marginBottom: "1rem",
                                maxWidth: "40%",
                            }}
                        />
                        <ArrowCircleRightRounded
                            sx={{

                                marginLeft: "1rem",
                                marginRight: "1rem",
                            }}
                        />
                        {selectedFile &&
                            <Chip
                                label={selectedFile.name}
                                color="success"
                                sx={{
                                    marginBottom: "1rem",
                                    maxWidth: "40%",
                                }}
                            />
                        }
                    </Grid>
                    <Typography
                        variant="body2"
                        sx={{
                            fontStyle: "italic",
                        }}
                    >Recuerda que el nombre del archivo permanecerá igual...
                    </Typography>


                    <DialogActions>
                        <Button
                            variant="contained"
                            color="error"
                            disabled={isUploadingFile}
                            onClick={() => {
                                setSelectedFile(null)
                                handleClose()
                            }}>Cancelar</Button>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={!selectedFile || isUploadingFile}
                            onClick={handleAssign} >
                            Subir
                        </Button>
                    </DialogActions>

                    {(isUploadingFile && <Typography
                        variant="body2"
                        sx={{
                            fontStyle: "italic",
                            color: "red"
                        }}
                    >Subiendo archivo, esto puede tardar unos minutos...
                    </Typography>
                    )}
                </DialogContent>
            </>

        </Dialog >
    );
};
