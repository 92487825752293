import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import SalesTable from './SalesTable';
import ClientFilterInput from './ClientFilterInput';
import SalesChart from './SalesChart';
import { downloadSalesCustomerSellerMonthXLS, fetchSalesClientMonth } from '../../../../../services/statisticsService';
import { getSellersNames } from '../../../../../services/sellerService';

export const SalesPerClientView = () => {
    const [selectedSeller, setSelectedSeller] = useState('All')
    const { statistics, isLoading } = useAppSelector((s: any) => s.statisticsReducer);
    const [selectedClients, setSelectedClient] = useState<string[]>([]);
    const [filterText, setFilterText] = useState('');
    const [dateSelected, setDateSelected] = useState<string[]>([]);
    const [isDownloading, setisDownloading] = useState(false);
    const chartData = statistics.filter((item: { customer: string; }) => selectedClients.includes(item.customer));
    const handleClientSelection = (cliente: string) => {
        if (selectedClients.includes(cliente)) {
            setSelectedClient(prev => prev.filter(v => v !== cliente));
        } else {
            setSelectedClient(prev => [...prev, cliente]);
        }
    };
    const [sellerNames, setSellerNames] = React.useState<string[]>([]);
    const clearSelection = () => {
        setSelectedClient([]);
    };
    useEffect(() => {
        getSellersNames().then((data) => {
            setSellerNames(data);
        })
    }, [])
    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>) :
                <div className="flex-1 overflow-auto">
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        spacing={1}
                        paddingLeft={2}
                        paddingRight={2}
                        maxWidth={"auto"}
                    >
                        <Grid md={6} item >
                            <SalesTable
                                data={statistics}
                                filterText={filterText}
                                selectedClients={selectedClients}
                                handleClientSelection={handleClientSelection}
                                dateSelected={dateSelected}
                                setDateSelected={setDateSelected}
                            />
                        </Grid>
                        <Grid
                            md={6}
                            sx={{ paddingTop: 1 }}
                            item>
                            <Grid container spacing={1} direction={'row'}>
                                <Grid item>
                                    <ClientFilterInput
                                        onFilterChange={setFilterText}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ marginLeft: 2 }}
                                        variant="contained"
                                        disabled={selectedClients.length === 0}
                                        onClick={() => {
                                            clearSelection();
                                            setSelectedSeller("All")
                                            fetchSalesClientMonth();
                                            setFilterText("");
                                        }}
                                    >Limpiar selección</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        onClick={async () => {
                                            setisDownloading(true);
                                            await downloadSalesCustomerSellerMonthXLS("Ventas_cliente")
                                            setisDownloading(false);
                                        }}
                                        disabled={isDownloading}
                                    >
                                        Descargar reporte
                                    </Button>
                                </Grid>
                            </Grid>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={["All", ...sellerNames]}
                                style={{ width: 300 }}
                                value={selectedSeller}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Vendedor"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, value) => {
                                    setSelectedClient([]);
                                    if (value !== "All" && value !== null) {
                                        setSelectedSeller(value as string);
                                        fetchSalesClientMonth(value as string)
                                    }
                                    else {
                                        setSelectedSeller("All")
                                        fetchSalesClientMonth();
                                    }
                                }}
                            ></Autocomplete>
                            <SalesChart data={chartData} dateSelected={dateSelected} />
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}
