import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { SalesPerCustomer } from '../../../../../types/Statistics';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';

const transformDataForChart = (data: SalesPerCustomer[], dateSelected: string[]): any[] => {
    const chartData: any = {};
    data.forEach(clienteData => {
        Object.entries(clienteData.sales).forEach(([month, sales]) => {
            if (dateSelected.includes(month)) { // Verifica si el mes está en dateSelected
                if (!chartData[month]) {
                    chartData[month] = { month };
                }
                chartData[month][clienteData.customer] = sales;
            }
        });
    });

    return Object.values(chartData);
};

const generateColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 100%, 70%)`; // Usamos HSL para variar el tono manteniendo la saturación y luminosidad constantes
};

const SalesChart: React.FC<{ data: SalesPerCustomer[], dateSelected: string[] }> = ({ data, dateSelected }) => {
    const chartData = transformDataForChart(data, dateSelected).sort((a, b) => Date.parse(a.month) - Date.parse(b.month));
    const dataWithColors = data.map(customerData => ({
        ...customerData,
        color: generateColor()
    }));


    return (
        <ResponsiveContainer width="100%" height={600}>
            <LineChart data={chartData}>
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="month" />
                <YAxis
                    tickFormatter={(value) =>
                        new Intl.NumberFormat("es-CL", {
                            notation: "compact",
                            compactDisplay: "short",
                        }).format(value)
                    }
                />
                <Tooltip
                    formatter={(value) =>
                        formatChileanLocale(value as number)
                    }
                />
                <Legend />
                {dataWithColors.map(customerData => (
                    <Line
                        strokeWidth={4}
                        key={customerData.customer}
                        type="monotone"
                        dataKey={customerData.customer}
                        stroke={customerData.color}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SalesChart;
