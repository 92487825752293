interface State {
    typists: Typist[];
    isLoading: boolean;
}

const initialState: State = {
    typists: [],
    isLoading: false,
};

export enum TypistActions {
    RequestTypists = "REQUEST_TYPISTS",
    ReceiveTypists = "RECEIVE_TYPISTS",
    AddTypists = "ADD_TYPISTS",
    DeleteTypists = "DELETE_TYPISTS",
}
// el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
const reducer = (
    state: State = initialState,
    { type, payload }: { type: TypistActions; payload: any }
): State => {
    switch (type) {
        case TypistActions.RequestTypists:
            console.log("request typists") //hago el llamado para que la api me traiga los producots
            return { ...state, isLoading: true };
        case TypistActions.ReceiveTypists:
            console.log("save supervisors") //recibo el arreglo de productos
            return { ...state, typists: payload, isLoading: false };
        case TypistActions.AddTypists: //agrego un producto
            return {
                ...state,
                typists: [
                    ...state.typists.filter((p) => p._id !== payload._id),
                    payload,
                ],
            };
        case TypistActions.DeleteTypists:
            return {
                ...state,
                typists: state.typists.filter((p) => p._id !== payload._id),
            };
        default:
            return state;
    }
};

export default reducer;
