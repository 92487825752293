import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useAppSelector } from '../../../../hooks/storeHooks';

import {
  convertToStringDate,
  getPriceList,
} from '../../../../utils/dateManager';

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  order: Order | Quotation | null; //esto es para que el modal se pueda usar para pedidos y cotizaciones
}

const DetailedOrderView: React.FC<Props> = ({ open, onClose, order }) => {
  const sellers = useAppSelector((state: any) => state.sellerReducer.sellers); //esto es para el modal
  const orderId = order?.date ? new Date(order?.date) : ''; //esto es para el modal
  const getSellerName = (sellerId: string) => {
    const seller = sellers.find(
      (seller: { _id: string }) => seller._id === sellerId
    );
    return seller ? seller.name : '';
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Detalle de pedido</DialogTitle>
      <DialogContent>
        <div className='flex flex-col gap-2 mt-5'>
          <div>Cliente: {order?.customer.name}</div>
          <div>Dirección Facturación: {order?.customer.address}</div>
          <div>Dirección Despacho: {order?.shippingAddress}</div>
          <div>Comuna: {order?.customer.commune}</div>
          <div>Ciudad: {order?.customer.city}</div>
          <div>Región: {order?.customer.region}</div>
          <div>Rut: {order?.customer.rut}</div>
          <div>Forma De Pago: {order?.customer.paymentForm}</div>
          <div>Comentarios: {order?.comments}</div>
          <div>Email: {order?.email}</div>
          <div>
            Numero de referencia: {orderId === '' ? '' : orderId.getTime()}
          </div>
          <div>Fecha: {convertToStringDate(order?.date)}</div>
          <div>Vendedor: {getSellerName(order?.seller || '')}</div>
          <div>Lista De Precios: {getPriceList(order?.priceList || '')}</div>
          <div>Productos: </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Codigo</TableCell>
                <TableCell>Descripcion</TableCell>
                <TableCell>Precio Neto</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Descuento</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            {order?.products.map((product: any) => (
              <TableRow key={product._id}>
                <TableCell> {product.code}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>
                  {product.netPrice.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>{product.discount}</TableCell>
                <TableCell>
                  {product.total.toLocaleString('es-CL', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <div>
            Total Neto:{' '}
            {order?.netTotal.toLocaleString('es-CL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
          <div>
            Descuento al Total:{' '}
            {order?.discount.toLocaleString('es-CL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
          <div>
            Total Neto con descuento:{' '}
            {order?.discountedNetTotal.toLocaleString('es-CL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
          <div>
            IVA:{' '}
            {order?.iva.toLocaleString('es-CL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
          <div>
            Total:{' '}
            {order?.total.toLocaleString('es-CL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DetailedOrderView;
