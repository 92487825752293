import * as React from 'react';
import './VentasDataGrid.css';
import {
  GridRow,
  SalesPerDate,
  SalesPerSupplier,
} from '../../../../../types/Statistics';
import {
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  IconButton,
  TableRow,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';

const transformData = (data: SalesPerSupplier[]): GridRow[] => {
  return data.map((item, index) => ({
    id: index,
    supplier: item.supplier,
    ...item.sales,
  }));
};

const getAllColumns = (data: SalesPerSupplier[]): string[] => {
  const allColumns: Set<string> = new Set();
  data.forEach((item) => {
    Object.keys(item.sales).forEach((column) => {
      allColumns.add(column);
    });
  });
  return Array.from(allColumns).sort(); // Ordenar las columnas cronológicamente
};

const calculateTotals = (data: SalesPerSupplier[]): SalesPerDate => {
  const totals: SalesPerDate = {};

  data.forEach((item) => {
    Object.entries(item.sales).forEach(([key, value]) => {
      if (totals[key]) {
        totals[key] += value;
      } else {
        totals[key] = value;
      }
    });
  });

  return totals;
};
const SalesTable: React.FC<{
  data: SalesPerSupplier[];
  filterText: string;
  selectedSupplier: any;
  handleSupplierSelection: (cliente: string) => void;
  dateSelected: string[];
  setDateSelected: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ data, filterText, selectedSupplier, handleSupplierSelection, dateSelected, setDateSelected }) => {
  const rows = transformData(data);
  const allColumns = getAllColumns(data);
  const totals = calculateTotals(data);
  const [sortColumn, setSortColumn] = useState(''); // Estado para rastrear la columna de ordenación
  const [sortDescending, setSortDescending] = useState(false); // Estado para rastrear la dirección de ordenación
  const [filteredRows, setFilteredRows] = useState(
    rows.filter((row) =>
      row.supplier!.toLowerCase().includes(filterText.toLowerCase())
    )
  ); // Estado para rastrear las filas filtradas y ordenadas

  const filterAndSortRows = () => {
    let filtered = rows.filter((row) =>
      row.supplier!.toLowerCase().includes(filterText.toLowerCase())
    );

    // Ordenar las filas si se ha seleccionado una columna de ordenación
    if (sortColumn) {
      filtered = [...filtered].sort((a, b) => {
        const aValue: any =
          typeof a[sortColumn] === 'number' ? a[sortColumn] : 0;
        const bValue: any =
          typeof b[sortColumn] === 'number' ? b[sortColumn] : 0;

        return sortDescending ? bValue - aValue : aValue - bValue;
      });
    }

    // Actualizar el estado con las filas filtradas y ordenadas
    setFilteredRows(filtered);
  };
  // Función para manejar el clic en el encabezado de la columna
  const handleColumnHeaderClick = (column: string) => {
    if (sortColumn === column) {
      // Si ya está ordenado por esta columna, cambiar la dirección de ordenación
      setSortDescending(!sortDescending);
    } else {
      // Si es una columna diferente, ordenar ascendentemente
      setSortColumn(column);
      setSortDescending(false);
    }
  };
  // Llamamos a filterAndSortRows cada vez que cambia el filtro o la columna de ordenación
  useEffect(() => {
    filterAndSortRows();
  }, [filterText, sortColumn, sortDescending]);

  return (
    <div className='h-full overflow-auto'>
      <Card style={{ border: 'solid', borderColor: 'grey', maxWidth: 1300 }}>
        <div>
          <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: 600 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ position: 'sticky', left: 0, zIndex: 1 }}
                  ></TableCell>
                  <TableCell
                    className='header-vendedor'
                    style={{ position: 'sticky', left: 50, zIndex: 1 }}
                  >
                    Proveedor
                  </TableCell>
                  {allColumns.map((column) => (
                    <TableCell className='header-columna' key={column}>
                      <Checkbox
                        checked={dateSelected.includes(column)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onClick={() => {
                          if (dateSelected.includes(column)) {
                            setDateSelected([
                              ...dateSelected.filter((item) => item !== column),
                            ]);
                          } else {
                            setDateSelected([...dateSelected, column]);
                          }
                        }}
                      ></Checkbox>
                      {column}
                      <IconButton
                        size='small'
                        onClick={() => handleColumnHeaderClick(column)}
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      className='cell-columna'
                      style={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                      }}
                    >
                      <Checkbox
                        checked={selectedSupplier.includes(row.supplier)}
                        onChange={() => handleSupplierSelection(row.supplier!)}
                      />
                    </TableCell>
                    <TableCell
                      className='cell-columna'
                      style={{
                        position: 'sticky',
                        left: 50,
                        zIndex: 1,
                        backgroundColor: 'white',
                      }}
                    >
                      {row.supplier}
                    </TableCell>
                    {allColumns.map((column) => (
                      <TableCell className='cell-columna' key={column}>
                        {typeof row[column] === 'number'
                          ? `$${formatChileanLocale(row[column] as number)}`
                          : '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    className='cell-columna'
                    style={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      bottom: 0,
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className='cell-columna'
                    style={{
                      position: 'sticky',
                      left: 50,
                      zIndex: 1,
                      backgroundColor: 'white',
                      bottom: 0,
                    }}
                  >
                    Totales
                  </TableCell>
                  {allColumns.map((column) => (
                    <TableCell
                      className='cell-columna'
                      key={column}
                      style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                      }}
                    >
                      {`$${formatChileanLocale(totals[column])}`}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </div>
  );
};

export default SalesTable;
