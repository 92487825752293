import axios from "./client";
import { SellerActions } from "../reducers/SellerReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;
const API_URL2 = process.env.REACT_APP_API_STATISTICS_URL;

export const fetchSellers = async () => {
  store.dispatch({ type: SellerActions.RequestSellers, payload: {} });
  const { data: sellers } = await axios.get<Seller[]>(`${API_URL}sellers`);
  store.dispatch({
    type: SellerActions.ReceiveSellers,
    payload: sellers,
  });
};
export const deleteSeller = async (id: string) => {
  const { data: seller } = await axios.delete<Seller>(
    `${API_URL}sellers/${id}`
  );
  store.dispatch({
    type: SellerActions.DeleteSeller,
    payload: seller,
  });
};
export const updateSeller = async (id: string, seller: Partial<Seller>) => {
  const { data: editedSeller } = await axios.patch<Seller>(
    `${API_URL}sellers/${id}`,
    seller
  );
  store.dispatch({
    type: SellerActions.AddSeller,
    payload: editedSeller,
  });
};
export const addSeller = async (seller: Partial<Seller>) => {
  const { data: newSeller } = await axios.post<Seller>(
    `${API_URL}sellers/`,
    seller
  );
  store.dispatch({
    type: SellerActions.AddSeller,
    payload: newSeller,
  });
};
export const changePassword = async (body: {
  username: string;
  password: string;
}) => {
  try {
    const { data: success } = await axios.post<boolean>(
      `${API_URL}sellers/passwordchange`,
      body
    );
    return success;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getSellersNames = async () => {
  const { data: { data } } = await axios.get(`${API_URL2}sellers`);
  return data.sellers.filter((seller: any) => seller.name !== null).map((seller: any) => seller.name);
}

export const reinitializeDevice = async (id: string, deviceQty: number) => {
  try {
    const response = await axios.post(`${API_URL}sellers/reinitializeDevices`, {
      deviceQty, id
    });
    return response.data;
  }
  catch (error) {
    console.log(error);
    return false;
  }
}

export const getSellerDeviceInfo = async (id: string) => {
  try {
    const response = await axios.get(`${API_URL}sellers/${id}/devices`);
    if (response === undefined) throw new Error("No response")
    return {
      activeDevices: response.data.activeDevices,
      deviceQty: response.data.deviceQty
    }
  }
  catch (error) {
    return {
      activeDevices: 0,
      deviceQty: 0
    }
  }
}
export const resetDevices = async (body: { id: string, deviceQty: number }) => {
  try {
    const response = await axios.post(`${API_URL}sellers/reinitializeDevices`, body);
    return response.data;
  }
  catch (error) {
    console.log(error);
    return false;
  }

}