interface State {
  quotations: Quotation[];
  isLoading: boolean;
}

const initialState: State = {
  quotations: [],
  isLoading: false,
};

export enum QuotationActions {
  RequestQuotations = "REQUEST_QUOTATIONS",
  ReceiveQuotations = "RECEIVE_QUOTATIONS",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: QuotationActions; payload: any }
): State => {
  switch (type) {
    case QuotationActions.RequestQuotations:
      return { ...state, isLoading: true };
    case QuotationActions.ReceiveQuotations:
      return { ...state, quotations: payload, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
