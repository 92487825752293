import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GridRow,
  SalesPerDate,
  SalesPerProduct,
} from '../../../../../types/Statistics';
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
} from '@mui/material';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const transformData = (
  data: SalesPerProduct[],
  columns: string[],
  totals: SalesPerDate
): GridRow[] => {
  // const lastMonth = columns[columns.length - 3];
  return data
    .map((item, index) => ({
      id: index,
      product: item.product,
      description: item.description,
      ...item.sales,
      ['Promedio 3 meses atrás']: averageLast3Months(
        {
          id: index,
          product: item.product,
          ...item.sales,
        },
        columns
      ),
      ['Participación último mes']: calculateParticipation(
        {
          id: index,
          product: item.product,
          ...item.sales,
        },
        columns,
        totals
      ),
    }))
    .sort((a, b) => {
      if (a['Participación último mes'] > b['Participación último mes']) {
        return -1;
      } else if (
        a['Participación último mes'] < b['Participación último mes']
      ) {
        return 1;
      } else {
        return 0;
      }
    });
};

const averageLast3Months = (data: GridRow, columns: string[]): number => {
  const last3Months = columns.slice(columns.length - 5, columns.length - 2);
  const average =
    last3Months
      .map((month) => {
        if (data[month] === undefined) {
          return 0;
        }
        return data[month] as number;
      })
      .reduce((a, b) => a + b, 0) / last3Months.length;

  return average;
};

const calculateParticipation = (
  data: GridRow,
  columns: string[],
  totals: SalesPerDate
): number => {
  const lastMonth = columns[columns.length - 3];
  const lastMonthValue = data[lastMonth] as number;
  if (lastMonthValue === undefined) {
    return 0;
  }
  const participation = (lastMonthValue / totals[lastMonth]) * 100;
  return participation;
};

const getAllColumns = (data: SalesPerProduct[]): string[] => {
  const allColumns: Set<string> = new Set();
  data.forEach((item) => {
    Object.keys(item.sales).forEach((column) => {
      allColumns.add(column);
    });
  });
  allColumns.add('Promedio 3 meses atrás');
  allColumns.add('Participación último mes');
  return Array.from(allColumns).sort();
};

export const SalesTable: React.FC<{
  filterText: string;
  data: SalesPerProduct[];
  totals: SalesPerDate;
}> = ({ data, filterText, totals }) => {
  const [rows, setRows] = useState<GridRow[]>([]);
  const [allColumns, setAllColumns] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [sortColumn, setSortColumn] = useState(''); // Estado para rastrear la columna de ordenación
  const [sortDescending, setSortDescending] = useState(false);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );
  const handleColumnHeaderClick = (column: string) => {
    if (sortColumn === column) {
      // Si ya está ordenado por esta columna, cambiar la dirección de ordenación
      setSortDescending(!sortDescending);
    } else {
      // Si es una columna diferente, ordenar ascendentemente
      setSortColumn(column);
      setSortDescending(false);
      setPage(0);
    }
  };
  useEffect(() => {
    // Actualiza allColumns cuando data cambia
    setAllColumns(getAllColumns(data));
  }, [data]);

  useEffect(() => {
    // Actualiza rows cuando allColumns y totals cambian
    setRows(transformData(data, allColumns, totals));
  }, [allColumns, totals]);

  const filteredRows = useMemo(() => {
    let filtered = rows.filter(
      (row) =>
        row.product?.toLowerCase().includes(filterText.toLowerCase()) ||
        row.description?.toLowerCase().includes(filterText.toLowerCase())
    );

    if (sortColumn) {
      filtered = [...filtered].sort((a, b) => {
        const aValue: any =
          typeof a[sortColumn] === 'number' ? a[sortColumn] : 0;
        const bValue: any =
          typeof b[sortColumn] === 'number' ? b[sortColumn] : 0;

        return sortDescending ? bValue - aValue : aValue - bValue;
      });
    }
    return filtered;
  }, [filterText, rows, sortColumn, sortDescending]);

  return (
    <div>
      <Card style={{ border: 'solid', borderColor: 'grey', maxWidth: 1000 }}>
        <div>
          <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: 600 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: '#ef4136',
                    }}
                  >
                    Código
                  </TableCell>
                  <TableCell style={{ backgroundColor: '#ef4136' }}>
                    Descripción
                  </TableCell>
                  {allColumns.map((column) => (
                    <TableCell
                      style={{ backgroundColor: '#ef4136' }}
                      key={column}
                    >
                      {column}
                      <IconButton
                        size='small'
                        onClick={() => handleColumnHeaderClick(column)}
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        className='cell-columna'
                        style={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: 'white',
                        }}
                      >
                        {row.product}
                      </TableCell>
                      <TableCell className='cell-columna'>
                        {row.description}
                      </TableCell>
                      {allColumns.map((column) => {
                        if (column === 'Participación último mes') {
                          return (
                            <TableCell
                              className='cell-columna'
                              style={{ color: 'red' }}
                              key={column}
                            >{`${(row[column] as number).toFixed(
                              2
                            )}%`}</TableCell>
                          );
                        } else if (column === 'Promedio 3 meses atrás') {
                          return (
                            <TableCell
                              className='cell-columna'
                              style={{ color: 'green' }}
                              key={column}
                            >
                              {typeof row[column] === 'number'
                                ? `$${formatChileanLocale(
                                    row[column] as number
                                  )}`
                                : '-'}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell className='cell-columna' key={column}>
                              {typeof row[column] === 'number'
                                ? `$${formatChileanLocale(
                                    row[column] as number
                                  )}`
                                : '-'}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Card>
    </div>
  );
};
