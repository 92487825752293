import logo from "./logohorizontalTF.jpeg";

const Logo = () => {
  return (
    <div className="flex justify-end">
      <img src={logo} alt="logo" width="400"></img>
    </div>
  );
};

export default Logo;
