import {
    DataGrid,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridRenderCellParams,
} from "@mui/x-data-grid";
import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";

import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

const SellerReportView: React.FC = () => {
    const { sellers, isLoading } = useAppSelector((s: any) => s.sellerReducer);
    const { supervisors } = useAppSelector((s: any) => s.supervisorReducer);
    const user = useAppSelector((s: any) => s.authReducer.user);
    const [filteredSellers, setFilteredSellers] = useState<Seller[]>([])
    const [totalMonths, setTotalMonths] = useState({
        total: "",
        month1sales: 0,
        month2sales: 0,
        month3sales: 0,
        month4sales: 0,
        month5sales: 0,
        month6sales: 0,
    })

    useEffect(() => {
        let newTotalMonths = {
            total: "",
            month1sales: 0,
            month2sales: 0,
            month3sales: 0,
            month4sales: 0,
            month5sales: 0,
            month6sales: 0,
        }
        const subordinates = supervisors.find((s: Supervisor) => s._id === user.supervisor._id)?.subordinates as string[];
        const filteredSellers = sellers.filter((s: Seller) => subordinates.includes(s._id));
        for (const seller of filteredSellers) {
            newTotalMonths.month1sales += seller.month1sales;
            newTotalMonths.month2sales += seller.month2sales;
            newTotalMonths.month3sales += seller.month3sales;
            newTotalMonths.month4sales += seller.month4sales;
            newTotalMonths.month5sales += seller.month5sales;
            newTotalMonths.month6sales += seller.month6sales;
        }
        setFilteredSellers(filteredSellers);
        setTotalMonths(newTotalMonths);
    }, [sellers])



    let month1 = new Date();
    let month2 = new Date();
    month2.setMonth(month1.getMonth() - 1);
    let month3 = new Date();
    month3.setMonth(month1.getMonth() - 2);
    let month4 = new Date();
    month4.setMonth(month1.getMonth() - 3);
    let month5 = new Date();
    month5.setMonth(month1.getMonth() - 4);
    let month6 = new Date();
    month6.setMonth(month1.getMonth() - 5);

    return (
        <div className="h-full overflow-auto">
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <Card>
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        columns={[
                            {
                                field: "total",
                                headerName: "Total",
                                width: 150,
                            },
                            {
                                field: "month6sales",
                                headerName: month6.toLocaleDateString("es-ES", {
                                    weekday: undefined,
                                    year: undefined,
                                    month: "long",
                                    day: undefined,
                                }),
                                width: 100,
                                renderCell(params: GridRenderCellParams) {
                                    return params.row.month6sales.toLocaleString("es-CL", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    });
                                },
                            },
                            {
                                field: "month5sales",
                                headerName: month5.toLocaleDateString("es-ES", {
                                    weekday: undefined,
                                    year: undefined,
                                    month: "long",
                                    day: undefined,
                                }),
                                width: 100,
                                renderCell(params: GridRenderCellParams) {
                                    return params.row.month5sales.toLocaleString("es-CL", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    });
                                },
                            },
                            {
                                field: "month4sales",
                                headerName: month4.toLocaleDateString("es-ES", {
                                    weekday: undefined,
                                    year: undefined,
                                    month: "long",
                                    day: undefined,
                                }),
                                width: 100,
                                renderCell(params: GridRenderCellParams) {
                                    return params.row.month4sales.toLocaleString("es-CL", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    });
                                },
                            },
                            {
                                field: "month3sales",
                                headerName: month3.toLocaleDateString("es-ES", {
                                    weekday: undefined,
                                    year: undefined,
                                    month: "long",
                                    day: undefined,
                                }),
                                width: 100,
                                renderCell(params: GridRenderCellParams) {
                                    return params.row.month3sales.toLocaleString("es-CL", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    });
                                },
                            },
                            {
                                field: "month2sales",
                                headerName: month2.toLocaleDateString("es-ES", {
                                    weekday: undefined,
                                    year: undefined,
                                    month: "long",
                                    day: undefined,
                                }),
                                width: 100,
                                renderCell(params: GridRenderCellParams) {
                                    return params.row.month2sales.toLocaleString("es-CL", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    });
                                },
                            },

                            {
                                field: "month1sales",
                                headerName: month1.toLocaleDateString("es-ES", {
                                    weekday: undefined,
                                    year: undefined,
                                    month: "long",
                                    day: undefined,
                                }),
                                width: 100,
                                renderCell(params: GridRenderCellParams) {
                                    return params.row.month1sales.toLocaleString("es-CL", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    });
                                },
                            },
                        ]
                        }
                        rows={[totalMonths]}
                        getRowId={(row) => row.total}
                        pageSize={1}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableColumnMenu
                    />
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        columns={
                            [
                                {
                                    field: "name",
                                    headerName: "Vendedor",
                                    width: 150,
                                },
                                {
                                    field: "month6sales",
                                    headerName: month6.toLocaleDateString("es-ES", {
                                        weekday: undefined,
                                        year: undefined,
                                        month: "long",
                                        day: undefined,
                                    }),
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.month6sales.toLocaleString("es-CL", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                },
                                {
                                    field: "month5sales",
                                    headerName: month5.toLocaleDateString("es-ES", {
                                        weekday: undefined,
                                        year: undefined,
                                        month: "long",
                                        day: undefined,
                                    }),
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.month5sales.toLocaleString("es-CL", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                },
                                {
                                    field: "month4sales",
                                    headerName: month4.toLocaleDateString("es-ES", {
                                        weekday: undefined,
                                        year: undefined,
                                        month: "long",
                                        day: undefined,
                                    }),
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.month4sales.toLocaleString("es-CL", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                },
                                {
                                    field: "month3sales",
                                    headerName: month3.toLocaleDateString("es-ES", {
                                        weekday: undefined,
                                        year: undefined,
                                        month: "long",
                                        day: undefined,
                                    }),
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.month3sales.toLocaleString("es-CL", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                },
                                {
                                    field: "month2sales",
                                    headerName: month2.toLocaleDateString("es-ES", {
                                        weekday: undefined,
                                        year: undefined,
                                        month: "long",
                                        day: undefined,
                                    }),
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.month2sales.toLocaleString("es-CL", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                },

                                {
                                    field: "month1sales",
                                    headerName: month1.toLocaleDateString("es-ES", {
                                        weekday: undefined,
                                        year: undefined,
                                        month: "long",
                                        day: undefined,
                                    }),
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.month1sales.toLocaleString("es-CL", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                },
                            ]}
                        rows={filteredSellers}
                        pageSize={20}
                        getRowId={(row) => row._id}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: CustomToolbar }}
                    />
                </Card>
            )}
        </div>
    );
};

export default SellerReportView;
