export const convertToStringDate = (nonformatedDate) => {
  const date = new Date(nonformatedDate);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} `;
};

export const getPriceList = (priceList) => {
  if (priceList === "priceRM") {
    return "Precio RM y V";
  } else if (priceList === "priceMaule") {
    return "Precio Maule";
  } else if (priceList === "priceCoquimbo") {
    return "Precio Coquimbo";
  } else if (priceList === "priceVI") {
    return "precio O´Higgins";
  } else if (priceList === "priceConstitucion") {
    return "Precio Constitución";
  } else if (priceList === "priceÑuble") {
    return "Precio Ñuble";
  } else if (priceList === "priceBiobio") {
    return "Precio Bio Bio";
  } else if (priceList === "priceAraucania") {
    return "Precio Araucania";
  } else if (priceList === "priceLosRios") {
    return "Los Ríos Los Lagos";
  } else if (priceList === "priceChiloe") {
    return "Precio Chiloe";
  } else if (priceList === "priceCopiapo") {
    return "Precio Copiapo";
  } else if (priceList === "priceVallenar") {
    return "Precio Vallenar";
  } else if (priceList === "priceCaldera") {
    return "Precio Caldera";
  }
};
