import { useRef, useState } from 'react';
import SalesTable from './SalesTable';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Button, Grid } from '@mui/material';
import { SalesChart } from './SalesChart';
import { SalesPerBranch } from '../../../../../types/Statistics';
import { downloadSalesBranchMonthXLS } from '../../../../../services/statisticsService';

const getAllColumns = (data: SalesPerBranch[]): string[] => {
  const allColumns: Set<string> = new Set();
  data.forEach((item) => {
    Object.keys(item.sales).forEach((column) => {
      allColumns.add(column);
    });
  });
  return Array.from(allColumns).sort(); // Ordenar las columnas cronológicamente
};

export const SalesPerBranchView = () => {
  const { statistics, isLoading } = useAppSelector(
    (s: any) => s.statisticsReducer
  );
  const [isDownloading, setisDownloading] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<string[]>([]);
  return (
    <div>
      {isLoading ? (
        <div className='flex justify-center w-full col-span-full'>
          <Icon spin path={mdiCircleSlice1} className='w-7'></Icon>
          Por favor espere...
        </div>
      ) : (
        <div>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
            paddingLeft={2}
            paddingRight={2}
            maxWidth={'auto'}
          >
            <Grid item md={6}>
              <Button
                style={{ marginBottom: 5, marginRight: 5 }}
                variant='contained'
                onClick={() => setDateSelected(getAllColumns(statistics))}
              >
                Seleccionar todo
              </Button>
              <Button
                style={{ marginBottom: 5, marginRight: 5 }}
                variant='contained'
                color='error'
                onClick={() => setDateSelected([])}
              >
                Deseleccionar todo
              </Button>
              <Button
                variant='contained'
                style={{ marginBottom: 5 }}
                onClick={async () => {
                  setisDownloading(true);
                  await downloadSalesBranchMonthXLS("Ventas_Sucursal.xlsx");
                  setisDownloading(false);
                }}
                disabled={isDownloading}
              >
                Descargar Reporte
              </Button>
              <SalesTable
                data={statistics}
                dateSelected={dateSelected}
                setDateSelected={setDateSelected}
              />
            </Grid>
            <Grid md={6} item>
              <SalesChart data={statistics} dateSelected={dateSelected} />
            </Grid>
          </Grid>
        </div>
      )
      }
    </div >
  );
};
