import React from 'react';
import { TextField } from '@mui/material';

interface SellerFilterInputProps {
    onFilterChange: (filterText: string) => void;
}

const SellerFilterInput: React.FC<SellerFilterInputProps> = ({ onFilterChange }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange(event.target.value);
    };

    return (
        <TextField
            label="Filtrar vendedor"
            variant="outlined"
            size="small"
            onChange={handleInputChange}
            style={{ marginBottom: '1rem' }}
        />
    );
};

export default SellerFilterInput;
