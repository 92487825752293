import Logo from "../../../Logo";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import {
  fetchCustomersBySupervisor,
} from "../../../../services/customerService";
import { fetchSellers } from "../../../../services/sellerService";
import {
  fetchSupervisors,
  generateSupervisorXls,
} from "../../../../services/supervisorService";
import SellerReportView from "./SellerReportView";
import VisitReportView from "./VisitReportView";
import GeoVisitView from "../../Common/GeoVisits/GeoVisitView";
import { SalesPerClientView } from "../../Manager/Statistics/SalesPerClient/SalesPerClientView";
import { OrdersReportView } from "../../Common/OrdersReport/OrdersReportView";
const ReportsView: React.FC = () => {
  const isLoadingSeller = useAppSelector((s: any) => s.sellerReducer).isLoading;
  const isLoadingCustomer = useAppSelector(
    (s: any) => s.customerReducer
  ).isLoading;
  const user = useAppSelector((s: any) => s.authReducer).user;
  const [tab, setTab] = useState<"client" | "seller" | "visits" | "geo" | "orders">("client"); //define el state para el componente
  const effectRan = useRef(false);

  const [inDownloading, setinDownloading] = useState(false);
  useEffect(() => {
    if (effectRan.current === false) {
      fetchCustomersBySupervisor(user.supervisor._id);
      fetchSellers();
      fetchSupervisors();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  return (
    <div className="p-5 h-full overflow-auto">
      <Logo />
      <div className="text-xl mb-3">Reportes</div>
      <Button
        variant="contained"
        color="primary"
        className="mb-3 ml-3"
        disabled={inDownloading}
        startIcon={
          inDownloading ? (
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          ) : null
        }
        onClick={async () => {
          setinDownloading(true);
          await generateSupervisorXls(user.supervisor._id, user.supervisor._id);
          setinDownloading(false);
        }}
      >
        Descargar
      </Button>
      {isLoadingSeller || isLoadingCustomer ? (
        <div className="flex justify-center w-full col-span-full">
          <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
        </div>
      ) : (
        <div className="h-full flex flex-col overflow-auto">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={(_, v) => {
                setTab(v as "client" | "seller");
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Por cliente" value={"client"} />
              <Tab label="Por vendedor" value={"seller"} />
              <Tab label="Por orden" value={"orders"} />
              <Tab label="Visitas" value={"visits"} />
              <Tab label="Geolocalizacion" value={"geo"} />
            </Tabs>
          </Box>
          <div className="flex-1 overflow-auto">
            {tab === "seller" && <SellerReportView />}
            {tab === "client" && <SalesPerClientView />}
            {tab === "orders" && <OrdersReportView />}
            {tab === "visits" && <VisitReportView />}
            {tab === "geo" && <GeoVisitView />}

          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsView;
