import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Alert, Button, Card } from "@mui/material";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { getRowsToArray } from "../../../../utils/readExcel";
import {
  fetchCustomers,
  upsertCustomers,
} from "../../../../services/customerService";
import { CustomerActions } from "../../../../reducers/CustomerReducer";
import store from "../../../../store";
import Logo from "../../../Logo";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const CustomerView: React.FC = () => {
  const { isLoading, customers, errorCustomers, errorMsg, success } =
    useAppSelector((state) => state.customerReducer);
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const effectRan = useRef(false);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Nombre", width: 200 },
    { field: "rut", headerName: "Rut", width: 200 },
    { field: "address", headerName: "Dirección", width: 200 },
    { field: "commune", headerName: "Comuna", width: 200 },
    { field: "city", headerName: "Ciudad", width: 200 },
    { field: "paymentForm", headerName: "Forma de pago", width: 200 },
  ];

  // function handleUpload(e: ChangeEvent<HTMLInputElement>): void {
  //   const file = e.target.files![0];
  //   getRowsToArray(file, "Clientes")
  //     .then((rows: any[]) => {
  //       upsertCustomers(rows);
  //       e.target.value = "";
  //     })
  //     .catch((err) => {
  //       if (typeof err === "string") {
  //         store.dispatch({
  //           type: CustomerActions.UpdateErrorMsg,
  //           payload: err,
  //         });
  //       } else {
  //         store.dispatch({
  //           type: CustomerActions.UpdateErrorMsg,
  //           payload: "Hubo un error",
  //         });
  //       }
  //       setErrorAlert(true);
  //       e.target.value = "";
  //     });
  // }
  useEffect(() => {
    if (effectRan.current === false) {
      fetchCustomers();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);
  useEffect(() => {
    if (errorCustomers.length > 0) {
      setErrorAlert(true);
    } else if (errorMsg !== "") {
      setErrorAlert(true);
    }
  }, [errorCustomers, errorMsg]);
  useEffect(() => {
    if (success) {
      setSuccessAlert(true);
    }
  }, [success]);

  return (
    <div className="p-5 h-full overflow-auto">
      <Logo />
      <div className="text-xl mb-3">Clientes</div>
      {errorAlert && (
        <Alert
          severity="error"
          onClose={() => {
            setErrorAlert(false);
          }}
        >
          {errorMsg !== "" ? errorMsg : "Hay un error en las filas"}{" "}
          {errorCustomers.map((error: { row: number }) => {
            return error.row + ", ";
          })}
        </Alert>
      )}
      {successAlert && (
        <Alert
          severity="success"
          onClose={() => {
            setSuccessAlert(false);
          }}
        >
          Se han subido los clientes correctamente
        </Alert>
      )}

      {/* <div className=" mt-2 mb-3 flex justify-end pr-7 space-x-2">
        <input
          accept=".xls, .xlsx"
          style={{ display: "none" }}
          id="contained-button-file-price"
          type="file"
          onChange={(e) => handleUpload(e)}
        />
        <label htmlFor="contained-button-file-price">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            size="large"
          >
            Carga Masiva
          </Button>
        </label>
      </div> */}
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={customers}
              pageSize={20}
              getRowId={(row) => row.rut}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
          </Card>
        )}
      </div>
    </div>
  );
};
export default CustomerView;
