interface State {
  orders: Order[];
  isLoading: boolean;
  error: string;
}

const initialState: State = {
  orders: [],
  isLoading: false,
  error: "",
};

export enum OrderActions {
  RequestOrders = "REQUEST_ORDERS",
  ReceiveOrders = "RECEIVE_ORDERS",
  SetError = "SET_ERROR",
  AddOrder = "ADD_ORDER",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: OrderActions; payload: any }
): State => {
  switch (type) {
    case OrderActions.RequestOrders:
      return { ...state, isLoading: true };
    case OrderActions.ReceiveOrders:
      return { ...state, orders: payload, isLoading: false };
    case OrderActions.SetError:
      return { ...state, error: payload, isLoading: false };
    case OrderActions.AddOrder:
      // if update, keep the same index
      const updatedOrderIndex = state.orders.findIndex(o => o._id === payload._id)
      if (updatedOrderIndex !== -1) {
        const updatedOrders = [...state.orders]
        updatedOrders[updatedOrderIndex] = payload
        return {
          ...state,
          isLoading: false,
          orders: updatedOrders
        }
      }
      return { ...state, orders: [...state.orders, payload], isLoading: false };
    default:
      return state;
  }
};

export default reducer;
