import { Delete, FileDownload, RecyclingOutlined } from '@mui/icons-material';
import { Alert, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  deleteProductListFile,
  fetchProductListFiles,
  getProductListFile,
} from '../../../../services/productService';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Logo from '../../../Logo';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { UpdateFileModal } from './UpdateFileModal';
import ProductFileList from '../../../../types/ProductFileList';
import { NewFileModal } from './NewFileModal';
import store from '../../../../store';
import { ProductListFileActions } from '../../../../reducers/ProductListFileReducer';

export const ProductList = () => {
  const { isLoading, products, error, msg, success } = useAppSelector(
    (s) => s.productListFilesReducer
  );
  const effect = useRef(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [NewFileModalOpen, setNewFileModalOpen] = useState(false);

  const handleButtonClick = () => {
    setTimeout(() => {
      store.dispatch({ type: ProductListFileActions.ClearError, payload: {} });
      store.dispatch({
        type: ProductListFileActions.ClearSuccess,
        payload: {},
      });
    }, 20000);
  };

  const [selectedFileToReplace, setSelectedFileToReplace] =
    useState<ProductFileList>({
      Key: '',
      LastModified: '',
      Etag: '',
      Size: 0,
      StorageClass: '',
    });

  useEffect(() => {
    if (!effect.current) {
      fetchProductListFiles();
    }
    return () => {
      effect.current = true;
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'fileName',
      minWidth: 250,
      flex: 1,
      headerName: 'Nombre del archivo',
      valueGetter(params) {
        return params.row.Key.split('/')[1];
      },
    },
    {
      field: 'lastModified',
      minWidth: 150,
      flex: 1,
      headerName: 'Última modificación',
      valueGetter(params) {
        const date = new Date(params.row.LastModified);
        return date.toLocaleString('es-ES');
      },
    },
    {
      field: 'replaceFile',
      minWidth: 180,
      flex: 1,
      headerName: 'Reemplazar Archivo',
      renderCell(params: GridRenderCellParams) {
        return (
          <Button
            variant='contained'
            color='warning'
            onClick={() => {
              setSelectedFileToReplace(params.row);
              setOpenDialog(true);
              handleButtonClick();
            }}
            endIcon={<RecyclingOutlined />}
          >
            Reemplazar
          </Button>
        );
      },
    },
    {
      field: 'deleteFile',
      minWidth: 180,
      flex: 1,
      headerName: 'Eliminar archivo',
      renderCell(params: GridRenderCellParams) {
        return (
          <Button
            variant='contained'
            color='error'
            onClick={async () => {
              await deleteProductListFile(params.row.Key);
              await fetchProductListFiles();
              handleButtonClick();
            }}
            endIcon={<Delete />}
          >
            Eliminar
          </Button>
        );
      },
    },
    {
      field: 'downloadFile',
      minWidth: 180,
      flex: 1,
      headerName: 'Descargar archivo',
      renderCell(params: GridRenderCellParams) {
        return (
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              getProductListFile(params.row.Key);
            }}
            endIcon={<FileDownload />}
          >
            Descargar
          </Button>
        );
      },
    },
  ];

  return (
    <div className='p-5 h-full overflow-auto'>
      <Logo />
      <div className='text-xl mb-3 '>Lista de productos</div>
      {error && (
        <Alert severity='error' className='mb-3'>
          {msg}
        </Alert>
      )}
      {success && (
        <Alert severity='success' className='mb-3'>
          {msg}
        </Alert>
      )}
      <div className='flex justify-between mb-3'>
        <Button
          variant='contained'
          color='primary'
          component='label'
          onClick={() => {
            setNewFileModalOpen(true);
          }}
        >
          Nuevo archivo
        </Button>
      </div>
      <div className='h-full flex flex-col overflow-auto'>
        <DataGrid
          rows={products || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.Key}
          loading={isLoading}
        />
      </div>
      <div>
        <UpdateFileModal
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          file={selectedFileToReplace}
        />
        <NewFileModal
          open={NewFileModalOpen}
          handleClose={() => setNewFileModalOpen(false)}
        />
      </div>
    </div>
  );
};
