import {
    DataGrid,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridRenderCellParams,
} from "@mui/x-data-grid";
import { Autocomplete, Button, Card, Chip, TextField } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import {
    fetchInvoicesSevenDays,
    getPdfUrl,
    updateSentWhatsappInvoice,
} from "../../../../services/invoiceService";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { phoneConversion } from "../../../../utils/phoneConverter";
import { fetchTrucks } from "../../../../services/truckService";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

interface Props {
    invoices: Invoice[];
    truckMap: Map<string, string>;
}

const TwoDaysAgoInvoices: React.FC<Props> = ({
    invoices,
    truckMap,
}) => {
    const { isLoading } = useAppSelector(
        (state: any) => state.invoiceReducer
    );
    const { trucks } = useAppSelector((state: any) => state.truckReducer);
    const effectRan = useRef(false);

    const [isLoadingMessage, setisLoadingMessage] = useState({
        message: "Cargando facturas...",
        state: false,
        _id_: null,
    });

    const handleWhatsappClick = async ({
        row: { paymentForm, phone, invoiceLaudusId, _id, sentToWhatsApp },
    }: GridRenderCellParams) => {
        if (paymentForm === null) {
            paymentForm = "";
        }

        if (!sentToWhatsApp) {
            setisLoadingMessage({
                message: "Guardando como enviada...",
                state: true,
                _id_: invoiceLaudusId,
            });
            await updateSentWhatsappInvoice(_id, !sentToWhatsApp);
        }

        const convertedPhone = phoneConversion(phone);
        setisLoadingMessage({
            message: "Cargando factura en la nube...",
            state: true,
            _id_: invoiceLaudusId,
        });
        const url = await getPdfUrl(invoiceLaudusId);
        const text = encodeURI(
            `¡Saludos! Le escribe FABIANA SÁNCHEZ de *DISTRIBUIDORA TODO FERRETERO SPA*, Departamento de crédito y cobranzas. Le comento que el día de HOY sale una factura a despacho con forma de pago *${paymentForm}* a su nombre. Le envío la factura por este medio para su verificación y pueda tener el pago al momento de recibir su pedido. ¡Muchas Gracias!\n\nPDF: ${url}`
        );
        window.open(`https://wa.me/+56${convertedPhone}?text=${text}`);
        setisLoadingMessage({
            message: "Cargando facturas...",
            state: false,
            _id_: null,
        });
    };

    useEffect(() => {
        if (effectRan.current === false) {
            fetchInvoicesSevenDays();
            return () => {
                effectRan.current = true;
            };
        }
    }, [isLoading]);


    const columns: GridColDef[] = [
        {
            field: "id",
            minWidth: 100,
            flex: 1,
            headerName: "ID Factura",

            valueGetter(params) {
                return params.row.invoiceLaudusId;
            },
        },
        {
            field: "customer",
            minWidth: 200,
            flex: 1,
            headerName: "Cliente",
            valueGetter(params) {
                return params.row.name;
            },
        },
        {
            field: "paymentForm",
            minWidth: 100,
            flex: 1,
            headerName: "Pago",
            valueGetter(params) {
                return params.row.paymentForm;
            },
        },
        {
            field: "fecha",
            minWidth: 100,
            flex: 1,
            headerName: "Fecha",
            valueGetter(params) {
                const date = new Date(params.row.date);
                return `${date.getDate()}/${date.getMonth() + 1
                    }/${date.getFullYear()} `;
            },
        },
        {
            field: "truck",
            minWidth: 100,
            flex: 1,
            headerName: "Camión",
            valueGetter(params) {
                if (!params.row.truck) {
                    return "No tiene";
                }
                return truckMap.get(params.row.truck);

            },
        },
        {
            field: "status",
            minWidth: 200,
            flex: 1,
            headerName: "Estado",
            renderCell(params: GridRenderCellParams) {
                return (
                    <Chip
                        label={params.row.status}
                        color={
                            params.row.status === "PENDIENTE DESPACHO" || params.row.status === "REPROGRAMADO"
                                ? "warning"
                                : params.row.status === "ENTREGADO SIN OBSERVACIONES" || params.row.status === "ENTREGADO CON OBSERVACIONES"
                                    ? "success"
                                    : "error"
                        }
                    />
                );
            }
        },
        {
            field: "fechaDespacho",
            minWidth: 150,
            flex: 1,
            headerName: "Fecha de Despacho",
            valueGetter(params) {
                const date = new Date(params.row.assignedTruckDate);
                if (!params.row.assignedTruckDate) {
                    return "No tiene";
                }
                return date.toLocaleDateString("es-CL");
            },
        },
        {
            field: "Open Whatsapp",
            minWidth: 180,
            flex: 1,
            headerName: "Abrir en WhatsApp",
            renderCell(params: GridRenderCellParams) {
                return (
                    <Button
                        variant="contained"
                        sx={{ backgroundColor: "Green" }}
                        disabled={
                            params.row.phone.replace(/[\s+]+/g, "").length < 9 ||
                            params.row.phone === "" ||
                            params.row.netAmount <= 0
                        }
                        size="small"
                        endIcon={<WhatsAppIcon />}
                        onClick={async (event) => {
                            event.preventDefault();
                            await handleWhatsappClick(params);
                        }}
                    >
                        {params.row.sentToWhatsApp ? "Reenviar" : "Compartir por"}
                    </Button>
                );
            },
        },
        {
            field: "sellerName",
            minWidth: 200,
            flex: 1,
            headerName: "Nombre Vendedor",
            valueGetter(params) {
                return params.row.sellerName;
            },
        },
        {
            field: "phone",
            minWidth: 100,
            flex: 1,
            headerName: "N° Teléfono",
            valueGetter(params) {
                if (!params.row.phone) {
                    return "No tiene";
                }
                return phoneConversion(params.row.phone);
            },
        },
    ];

    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : isLoadingMessage.state ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    <p className="ml-2">{isLoadingMessage.message}</p>
                </div>
            ) : (
                <div>
                    <Card
                        sx={{
                            borderRadius: 2,
                            padding: 2,
                            margin: 2,
                        }}
                    >
                        Por enviar
                        <DataGrid
                            sx={{
                                padding: 0,
                                "& .super-app-theme--true": {
                                    bgcolor: "#9ACD32",
                                },
                                border: 0,
                            }}
                            autoHeight={true}
                            columns={columns}
                            // rows={
                            //     (selectedClient === "") ?
                            //         invoices.filter(
                            //             (invoice: Invoice) => !invoice.sentToWhatsApp
                            //         ) :
                            //         invoices.filter(
                            //             (invoice: Invoice) => !invoice.sentToWhatsApp && invoice.name === selectedClient)
                            // }
                            rows={invoices}
                            pageSize={20}
                            getRowId={(row) => row.invoiceLaudusId}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            components={{ Toolbar: CustomToolbar }}
                        />
                    </Card>
                    <Card
                        sx={{
                            borderRadius: 2,
                            padding: 2,
                            margin: 2,
                        }}
                    >
                        Enviadas
                        <DataGrid
                            sx={{
                                padding: 0,
                                "& .super-app-theme--true": {
                                    bgcolor: "#9ACD32",
                                },
                                border: 0,
                                height: 400,
                            }}
                            columns={columns}
                            rows={invoices.filter(
                                (invoice: Invoice) => invoice.sentToWhatsApp
                            )}
                            pageSize={20}
                            getRowId={(row) => row.invoiceLaudusId}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            components={{ Toolbar: CustomToolbar }}
                        />
                    </Card>
                </div>
            )}
        </div>
    );
};

export default TwoDaysAgoInvoices;
