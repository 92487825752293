import axios from "./client";
import { TypistActions } from "../reducers/TypistReducer";
import store from "./../store";
import FileDownload from "js-file-download";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchTypists = async () => {
    store.dispatch({ type: TypistActions.RequestTypists, payload: {} });
    const { data: typists } = await axios.get<Typist[]>(`${API_URL}typists`);
    store.dispatch({
        type: TypistActions.ReceiveTypists,
        payload: typists,
    });
};
export const deleteTypist = async (id: string) => {
    const { data: typist } = await axios.delete<Typist>(
        `${API_URL}typists/${id}`
    );
    store.dispatch({
        type: TypistActions.DeleteTypists,
        payload: typist,
    });
};
export const updateTypist = async (id: string, typist: Partial<Typist>) => {
    const { data: editedTypist } = await axios.patch<Typist>(
        `${API_URL}typists/${id}`,
        typist
    );
    store.dispatch({
        type: TypistActions.AddTypists,
        payload: editedTypist,
    });
}
export const addTypist = async (typist: Partial<Typist>) => {
    const { data: newTypist } = await axios.post<Typist>(
        `${API_URL}typists/`,
        typist
    );
    store.dispatch({
        type: TypistActions.AddTypists,
        payload: newTypist,
    });
};
export const changePassword = async (body: {
    username: string;
    password: string;
}) => {
    try {
        const { data: success } = await axios.post<boolean>(
            `${API_URL}typists/passwordchange`,
            body
        );
        return success;
    } catch (error) {
        console.log(error);
        return false;
    }
};