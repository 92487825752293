import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  TableFooter,
  Checkbox,
  IconButton,
} from '@mui/material';
import { SalesPerBranch, SalesPerDate } from '../../../../../types/Statistics';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const calculateTotals = (data: SalesPerBranch[]): SalesPerDate => {
  const totals: SalesPerDate = {};

  data.forEach((item) => {
    Object.entries(item.sales).forEach(([key, value]) => {
      if (totals[key]) {
        totals[key] += value;
      } else {
        totals[key] = value;
      }
    });
  });
  return totals;
};

const getAllColumns = (data: SalesPerBranch[]): string[] => {
  const allColumns: Set<string> = new Set();
  data.forEach((item) => {
    Object.keys(item.sales).forEach((column) => {
      allColumns.add(column);
    });
  });
  return Array.from(allColumns).sort(); // Ordenar las columnas cronológicamente
};

const SalesTable: React.FC<{
  data: SalesPerBranch[];
  dateSelected: string[];
  setDateSelected: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ data, dateSelected, setDateSelected }) => {
  const allMonths = getAllColumns(data);
  const sortedMonths = Array.from(allMonths).sort();
  const totals = calculateTotals(data);

  const [sortColumn, setSortColumn] = useState(''); // Estado para rastrear la columna de ordenación
  const [sortDescending, setSortDescending] = useState(false); // Estado para rastrear la dirección de ordenación
  const [filteredRows, setFilteredRows] = useState(data);

  const sortRows = () => {
    if (sortColumn) {
      setFilteredRows([
        ...filteredRows.sort((a, b) => {
          const aValue: any =
            typeof a.sales[sortColumn] === 'number' ? a.sales[sortColumn] : 0;
          const bValue: any =
            typeof b.sales[sortColumn] === 'number' ? b.sales[sortColumn] : 0;

          return sortDescending ? bValue - aValue : aValue - bValue;
        }),
      ]);
    }
  };
  const handleColumnHeaderClick = (column: string) => {
    if (sortColumn === column) {
      // Si ya está ordenado por esta columna, cambiar la dirección de ordenación
      setSortDescending(!sortDescending);
    } else {
      // Si es una columna diferente, ordenar ascendentemente
      setSortColumn(column);
      setSortDescending(false);
    }
  };
  useEffect(() => {
    sortRows();
  }, [sortColumn, sortDescending]);

  return (
    <div className='h-full overflow-auto'>
      <Card className='w-full h-full' elevation={3}>
        <div style={{ border: 'solid', borderColor: 'grey', maxWidth: 1300 }}>
          <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: 600 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='header-vendedor'
                    style={{ position: 'sticky', left: 0, zIndex: 1 }}
                  >
                    Sucural
                  </TableCell>
                  {allMonths.map((column) => (
                    <TableCell className='header-columna' key={column}>
                      <Checkbox
                        checked={dateSelected.includes(column)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onClick={() => {
                          if (dateSelected.includes(column)) {
                            setDateSelected([
                              ...dateSelected.filter((item) => item !== column),
                            ]);
                          } else {
                            setDateSelected([...dateSelected, column]);
                          }
                        }}
                      ></Checkbox>
                      <div>{column}</div>
                      <IconButton
                        size='small'
                        onClick={() => handleColumnHeaderClick(column)}
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows.map((row) => (
                  <TableRow key={row.branch}>
                    <TableCell
                      className='cell-columna'
                      style={{
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                      }}
                    >
                      {row.branch}
                    </TableCell>
                    {sortedMonths.map((column) => (
                      <TableCell className='cell-columna' key={column}>
                        {typeof row.sales[column] === 'number'
                          ? `$${formatChileanLocale(
                              row.sales[column] as number
                            )}`
                          : '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    className='cell-columna'
                    style={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      bottom: 0,
                    }}
                  >
                    Totales
                  </TableCell>
                  {allMonths.map((column) => (
                    <TableCell
                      className='cell-columna'
                      key={column}
                      style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                      }}
                    >
                      ${formatChileanLocale(totals[column])}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </Card>
    </div>
  );
};

export default SalesTable;
