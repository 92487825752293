import axios from "axios";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import client from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  const {
    data: { token, user },
  } = await axios.post<{ token: string; user: { role: string } }>(
    `${API_URL}auth/login`,
    {
      username,
      password,
    }
  );
  if (user.role === "seller") {
    throw new Error("You are a seller");
  }
  if (user.role === "truck") {
    throw new Error("You are a Driver");
  }
  if (user.role === "logistic") {
    throw new Error("You are a Logistic");
  }
  store.dispatch({ type: AuthActions.SetSession, payload: { token, user } });
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
};

export const initLogin = async () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user")!)

  if (!user) {
    localStorage.removeItem("token")
    store.dispatch({ type: AuthActions.DelSession, payload: null });
  }

  if (token) {
    try {
      client.get(`${API_URL}auth/validate`, {
        headers: {
          "x-auth-token": token,
        },
      });
      store.dispatch({ type: AuthActions.SetSession, payload: { token, user: JSON.parse(localStorage.getItem("user") || "{}") } });
    } catch (error) {
      localStorage.removeItem("token");
      store.dispatch({ type: AuthActions.DelSession, payload: null });
    }
  }
};
