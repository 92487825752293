import React, { useEffect } from 'react'
import { useAppSelector } from '../../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { SalesTable } from './SalesTable';
import { QuantityTable } from './QuantityTable';
import { SalesPerBrand, SalesPerDate } from '../../../../../types/Statistics';
import ProductFilterInput from './ProductFilterInput';
import { downloadQuantityProductRankingMonthXLS, downloadSalesBranchMonthXLS, downloadSalesProductRankingMonthXLS, fetchRankingProducts, getFamiliesNames } from '../../../../../services/statisticsService';

const calculateTotals = (data: SalesPerBrand[]): SalesPerDate => {
    const totals: SalesPerDate = {};
    data.forEach(item => {
        Object.entries(item.sales).forEach(([key, value]) => {
            if (totals[key]) {
                totals[key] += value;
            } else {
                totals[key] = value;
            }
        });
    });
    return totals;
};


export const ProductRankingView = () => {
    const { statistics, isLoading } = useAppSelector((s: any) => s.statisticsReducer);
    const [filterText, setFilterText] = React.useState('');
    const [totals, setTotals] = React.useState<SalesPerDate>({});
    const [families, setFamilies] = React.useState<string[]>([]);
    const [selectedFamily, setSelectedFamily] = React.useState<string>("All")
    const [downloading, setDownloading] = React.useState(false)
    useEffect(() => {
        if (!isLoading) {
            setTotals(calculateTotals(statistics));
        }
    }, [statistics, isLoading]);

    useEffect(() => {
        getFamiliesNames().then((response) => {
            setFamilies(response);
        });
    }, []);

    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    Por favor espere...
                </div>) :
                <div>
                    <Grid container direction={"column"} justifyContent={"flex-end"} paddingLeft={2} spacing={1} maxWidth={"auto"} style={{ marginBottom: 2 }}>
                        <Grid container item direction="row" justifyContent={"center"} alignItems={"center"} alignContent={"center"} spacing={2}>
                            <Grid item  >
                                <ProductFilterInput onFilterChange={setFilterText} />
                            </Grid>
                            <Grid item  >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={[...families, "All"]}
                                    style={{ width: 300 }}
                                    value={selectedFamily}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Familia"
                                            variant="outlined"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        if (value !== "All" && value !== null) {
                                            setSelectedFamily(value as string);
                                            fetchRankingProducts(value as string)
                                        }
                                        else {
                                            setSelectedFamily("All")
                                            fetchRankingProducts();
                                        }
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item  >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={downloading}
                                    onClick={async () => {
                                        setDownloading(true);
                                        await downloadSalesProductRankingMonthXLS("Ranking Ventas");
                                        setDownloading(false);
                                    }}
                                >
                                    Descargar Ventas
                                </Button>
                            </Grid>
                            <Grid item  >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={downloading}
                                    onClick={async () => {
                                        setDownloading(true);
                                        await downloadQuantityProductRankingMonthXLS("Ranking Cantidad");
                                        setDownloading(false);
                                    }}
                                >
                                    Descargar Cantidad
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} paddingLeft={2} paddingRight={2} maxWidth={"auto"}>
                            <Grid md={6} item >
                                <SalesTable
                                    data={statistics}
                                    filterText={filterText}
                                    totals={totals}
                                />
                            </Grid>
                            <Grid md={6} item >
                                <QuantityTable
                                    data={statistics}
                                    filterText={filterText}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}
