import React, { useEffect } from 'react'
import { useAppSelector } from '../../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { downloadSalesBrandSellerMonthXLS, fetchSalesBrandMonth } from '../../../../../services/statisticsService';
import { getSellersNames } from '../../../../../services/sellerService';
import SalesTable from './SalesTable';
import BrandFilterInput from './BrandFilterInput';
import SalesChart from './SalesChart';
import { SalesPerDate, SalesPerFamily } from '../../../../../types/Statistics';

const calculateTotals = (data: SalesPerFamily[]): SalesPerDate => {
    const totals: SalesPerDate = {};
    data.forEach((item) => {
        Object.entries(item.sales!).forEach(([key, value]) => {
            if (totals[key]) {
                totals[key] += value;
            } else {
                totals[key] = value;
            }
        });
    });
    return totals;
};

export const SalesPerBrandView = () => {
    const [selectedSeller, setSelectedSeller] = React.useState('All')
    const { statistics, isLoading } = useAppSelector((s: any) => s.statisticsReducer);
    const [selectedBrands, setSelectedBrands] = React.useState<string[]>([]);
    const [chartData, setChartData] = React.useState<any[]>([]);
    const [filterText, setFilterText] = React.useState('');
    const [dateSelected, setDateSelected] = React.useState<string[]>([]);
    const [isDownloading, setisDownloading] = React.useState(false);
    const handleBrandSelection = (family: string) => {
        if (selectedBrands.includes(family)) {
            setSelectedBrands(prev => prev.filter(v => v !== family));
        } else {
            setSelectedBrands(prev => [...prev, family]);
        }
    };
    const [sellerNames, setSellerNames] = React.useState<string[]>([]);
    const clearSelection = () => {
        setSelectedBrands([]);
    };
    useEffect(() => {
        getSellersNames().then((data) => {
            setSellerNames(data);
        })
    }, [])
    useEffect(() => {
        console.log(statistics)
        setChartData(statistics.filter((item: { brand: string; }) => selectedBrands.includes(item.brand)))
    }, [selectedBrands])
    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    Por favor espere...
                </div>) :
                <div>
                    <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} paddingLeft={2} paddingRight={2} maxWidth={"auto"}>
                        <Grid md={6} item >
                            <SalesTable
                                data={statistics}
                                filterText={filterText}
                                selectedBrands={selectedBrands}
                                handleBrandSelection={handleBrandSelection}
                                dateSelected={dateSelected}
                                setDateSelected={setDateSelected}
                                totals={calculateTotals(statistics)}
                            />
                        </Grid>
                        <Grid md={6} item >
                            <Grid container spacing={1} direction={'row'}>
                                <Grid item>
                                    <BrandFilterInput
                                        onFilterChange={setFilterText}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ marginLeft: 2 }}
                                        variant="contained"
                                        disabled={selectedBrands.length === 0}
                                        onClick={() => {
                                            clearSelection();
                                            setSelectedSeller("All")
                                            fetchSalesBrandMonth();
                                            setFilterText("");
                                        }}
                                    >Limpiar selección</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        onClick={async () => {
                                            setisDownloading(true);
                                            await downloadSalesBrandSellerMonthXLS("Ventas_marca")
                                            setisDownloading(false);
                                        }}
                                        disabled={isDownloading}
                                    >

                                        Descargar reporte
                                    </Button>
                                </Grid>
                            </Grid>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={[...sellerNames, "All"]}
                                style={{ width: 300 }}
                                value={selectedSeller}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Vendedor"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, value) => {
                                    setSelectedBrands([]);
                                    if (value !== "All" && value !== null) {
                                        setSelectedSeller(value as string);
                                        fetchSalesBrandMonth(value as string)
                                    }
                                    else {
                                        setSelectedSeller("All")
                                        fetchSalesBrandMonth();
                                    }
                                }}
                            ></Autocomplete>

                            <SalesChart data={chartData} totals={calculateTotals(statistics)} dateSelected={dateSelected} />

                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}
