import axios from "./client";
import { TruckActions } from "../reducers/TrucksReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_LOGISTICS_URL;

export const fetchTrucks = async () => {
    store.dispatch({ type: TruckActions.RequestTrucks, payload: {} });
    const { data: trucks } = await axios.get<Truck[]>(`${API_URL}truck`);
    store.dispatch({
        type: TruckActions.ReceiveTrucks,
        payload: trucks,
    });
};