import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useForm } from "../../../../hooks/useForm";
import { addSeller, updateSeller } from "../../../../services/sellerService";
import { useEffect, useState } from "react";
import { AddCircle, DeleteForever } from "@mui/icons-material";

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
  seller: Seller | null;
  setSeller: (seller: Seller | null) => void;
}

const NewSellerModal: React.FC<Props> = ({
  open,
  onClose,
  seller,
  setSeller,
}) => {
  const initialValues = {
    name: "",
    username: "",
    priceList: "",
    emailList: [] as string[],
  };
  const [email, setEmail] = useState("");
  const [values, setValues, handleChange] = useForm(initialValues);
  useEffect(() => {
    if (seller) {
      setValues({
        ...values,
        name: seller.name,
        username: seller.username,
        priceList: seller.priceList,
        emailList: seller.emailList,
      });
    } else {
      setValues(initialValues);
    }
  }, [seller]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onTransitionEnd={() => !open && setValues(initialValues)} //para borrar los values solo cuando se cierra
    >
      <DialogTitle>Nuevo Vendedor</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2 mt-5">
          <TextField
            disabled={!!seller} //si hay un seller, es porque estoy editando, y no quiero que se pueda editar el nombre
            onChange={handleChange}
            id="username"
            label="Usuario"
            value={values.username}
          ></TextField>
          <TextField
            onChange={handleChange}
            id="name"
            label="Nombre"
            value={values.name}
          ></TextField>
          <FormControl>
            <InputLabel id="priceList-label">Lista de precio</InputLabel>
            <Select
              labelId="priceList-label"
              id="priceList"
              value={values.priceList}
              label="Lista de Precio"
              onChange={(e) => {
                setValues({ ...values, priceList: e.target.value });
              }}
            >
              <MenuItem value="priceRM">Precio RM y V</MenuItem>
              <MenuItem value="priceMaule">Precio Maule</MenuItem>
              <MenuItem value="priceCoquimbo">Precio Coquimbo</MenuItem>
              <MenuItem value="priceVI">precio O´Higgins</MenuItem>
              <MenuItem value="priceConstitucion">Precio Constitucion</MenuItem>
              <MenuItem value="priceÑuble">Precio Ñuble</MenuItem>
              <MenuItem value="priceBiobio">Precio Bio Bio</MenuItem>
              <MenuItem value="priceAraucania">Precio Araucania</MenuItem>
              <MenuItem value="priceChiloe">Precio Chiloe</MenuItem>
              <MenuItem value="priceVallenar">Precio Vallenar</MenuItem>
              <MenuItem value="priceCopiapo">Precio Copiapo</MenuItem>
              <MenuItem value="priceCaldera">Precio Caldera</MenuItem>
              <MenuItem value="priceLosRios">Los Rios Los Lagos</MenuItem>
            </Select>
          </FormControl>
          <div className="flex">
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              label="Email"
              value={email}
            ></TextField>
            <IconButton
              color="primary"
              onClick={() => {
                setValues({
                  ...values,
                  emailList: [...values.emailList, email],
                });
                setEmail("");
              }}
            >
              <AddCircle></AddCircle>
            </IconButton>
          </div>
          <div className="flex flex-col gap-2">
            {values.emailList.map((email) => (
              <div>
                {email}
                <IconButton
                  color="primary"
                  onClick={() => {
                    setValues({
                      ...values,
                      emailList: values.emailList.filter((e) => e !== email),
                    });
                  }}
                >
                  <DeleteForever></DeleteForever>
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (seller !== null) {
              setSeller(null);
              setValues(initialValues);
            }
            onClose();
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={
            values.username === "" ||
            values.name === "" ||
            values.priceList === ""
          }
          onClick={async () => {
            if (seller) {
              await updateSeller(seller._id, values);
              setSeller(null);
              onClose();
            } else {
              await addSeller(values);
              setSeller(null);
              onClose();
            }
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewSellerModal;
