import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import { Box, Button, Card, Tab, Tabs } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { mdiCircleSlice1 } from '@mdi/js';
import Icon from '@mdi/react';

import {
  fetchOrders,
  generateOrderPDF,
  generateOrderXLS,
  generateOrdersXls,
  updateOrder,
} from '../../../../services/orderService';
import { fetchSellers } from '../../../../services/sellerService';
import Logo from '../../../Logo';
import ActiveOrdersView from './ActiveOrdersView';
import DeletedOrdersView from './DeletedOrdersView';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const OrdersView: React.FC = () => {
  const { isLoading, orders } = useAppSelector(
    (state: any) => state.orderReducer
  );
  const effectRan = useRef(false);
  const [tab, setTab] = useState<'active' | 'deleted'>('active');
  const [isLoadingReport, setisLoadingReport] = useState(false);
  const { user } = useAppSelector((state: any) => state.authReducer);
  const [filteredOrders, setFilteredOrders] = useState({
    active: [],
    deleted: [],
  });

  useEffect(() => {
    const filteredActive = orders.filter(
      (order: Order) =>
        !order.deleted && user.typist.subordinates.includes(order.seller)
    );
    const filteredDeleted = orders.filter(
      (order: Order) =>
        order.deleted && user.typist.subordinates.includes(order.seller)
    );
    setFilteredOrders({
      active: filteredActive,
      deleted: filteredDeleted,
    });
  }, [orders]);

  useEffect(() => {
    if (effectRan.current === false) {
      fetchOrders();
      fetchSellers();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  return (
    <div className='p-5 h-full overflow-auto'>
      <Logo></Logo>
      <div className='text-xl mb-3'>Ordenes</div>
      <div className='mb-0 flex pr-7'>
        <Button
          variant='contained'
          disabled={isLoadingReport}
          onClick={async () => {
            setisLoadingReport(true);
            const downloadName = `${new Date(Date.now()).getTime()}`;
            await generateOrdersXls(downloadName);
            setisLoadingReport(false);
          }}
        >
          {isLoadingReport ? (
            <Icon spin path={mdiCircleSlice1} className='w-7'></Icon>
          ) : (
            'Descargar'
          )}
        </Button>
      </div>
      <div className='mb-3 flex justify-end pr-7'>
        <Button
          sx={{ marginTop: -5 }}
          variant='contained'
          onClick={() => {
            fetchOrders();
          }}
        >
          {' '}
          Actualizar
        </Button>
      </div>
      <div>
        {isLoading ? (
          <div className='flex justify-center w-full col-span-full'>
            <Icon spin path={mdiCircleSlice1} className='w-7'></Icon>
          </div>
        ) : (
          <Card>
            <div className='h-full flex flex-col overflow-auto'>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={(_, v) => {
                    setTab(v as 'active' | 'deleted');
                  }}
                  aria-label='basic tabs example'
                >
                  <Tab label='Vigentes' value={'active'} />
                  <Tab label='Eliminadas' value={'deleted'} />
                </Tabs>
              </Box>
              <div className='flex-1 overflow-auto'>
                {tab === 'active' && (
                  <ActiveOrdersView activeOrders={filteredOrders.active} />
                )}
                {tab === 'deleted' && (
                  <DeletedOrdersView deletedOrders={filteredOrders.deleted} />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default OrdersView;
