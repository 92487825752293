import axios from "./client";
import FileDownload from "js-file-download";

const API_URL = process.env.REACT_APP_API_URL;

export const getVisitsExcel = async () => {
  await axios
    .get(API_URL + `visits/excel`, {
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
      },
    })
    .then((res) => {
      FileDownload(res.data, `visits.xlsx`);
    });
};
