import React from 'react';
import { ActiveOrdersViewTypist } from '../../Common/Order/ActiveOrdersView';
interface Props {
  activeOrders: Order[];
}
const ActiveOrdersView: React.FC<Props> = ({ activeOrders }) => {
  return <ActiveOrdersViewTypist activeOrders={activeOrders} />;
};

export default ActiveOrdersView;
