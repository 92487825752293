import { Button } from "@mui/material";
import { useState } from "react";
import { getVisitsExcel } from "../../../../services/visitService";

const VisitReportView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDownload = async () => {
    setIsLoading(true);
    await getVisitsExcel();
    setIsLoading(false);
  };

  return (
    <div className="h-full overflow-auto">
      <div className="p-2">
        <Button
          disabled={isLoading}
          variant="contained"
          onClick={handleDownload}
        >
          Descargar informe de visitas
        </Button>
      </div>
    </div>
  );
};

export default VisitReportView;
