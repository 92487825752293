import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { downloadSalesSupplierSellerMonthXLS, fetchSalesSupplierMonth } from '../../../../../services/statisticsService';
import { getSellersNames } from '../../../../../services/sellerService';
import SupplierFilterInput from './SupplierFilterInput';
import SalesTable from './SalesTable';
import SalesChart from './SalesChart';

export const SalesPerSupplierView = () => {
    const [selectedSeller, setSelectedSeller] = useState('All')
    const { statistics, isLoading } = useAppSelector((s: any) => s.statisticsReducer);
    const [selectedSupplier, setSelectedSupplier] = useState<string[]>([]);
    const [filterText, setFilterText] = useState('');
    const [dateSelected, setDateSelected] = useState<string[]>([]);
    const [isDownloading, setisDownloading] = useState(false);
    const chartData = statistics.filter((item: { supplier: string; }) => selectedSupplier.includes(item.supplier));
    const handleSupplierSelection = (supplier: string) => {
        if (selectedSupplier.includes(supplier)) {
            setSelectedSupplier(prev => prev.filter(v => v !== supplier));
        } else {
            setSelectedSupplier(prev => [...prev, supplier]);
        }
    };
    const [sellerNames, setSellerNames] = React.useState<string[]>([]);
    const clearSelection = () => {
        setSelectedSupplier([]);
    };
    useEffect(() => {
        getSellersNames().then((data) => {
            setSellerNames(data);
        })
    }, [])
    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                    Por favor espere...
                </div>) :
                <div className="flex-1 overflow-auto">
                    <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} paddingLeft={2} paddingRight={2} maxWidth={"auto"} >
                        <Grid md={6} item >
                            <SalesTable
                                data={statistics}
                                filterText={filterText}
                                selectedSupplier={selectedSupplier}
                                handleSupplierSelection={handleSupplierSelection}
                                dateSelected={dateSelected}
                                setDateSelected={setDateSelected}
                            />
                        </Grid>
                        <Grid md={6} sx={{ paddingTop: 1 }} item>
                            <Grid container spacing={1} direction={'row'}>
                                <Grid item>
                                    <SupplierFilterInput
                                        onFilterChange={setFilterText}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ marginLeft: 2 }}
                                        variant="contained"
                                        disabled={selectedSupplier.length === 0}
                                        onClick={() => {
                                            clearSelection();
                                            setSelectedSeller("All")
                                            fetchSalesSupplierMonth();
                                            setFilterText("");
                                        }}
                                    >Limpiar selección</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant='contained'
                                        onClick={async () => {
                                            setisDownloading(true);
                                            await downloadSalesSupplierSellerMonthXLS("Ventas_proveedor")
                                            setisDownloading(false);
                                        }}
                                        disabled={isDownloading}
                                    >

                                        Descargar reporte
                                    </Button>
                                </Grid>
                            </Grid>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={[...sellerNames, "All"]}
                                style={{ width: 300 }}
                                value={selectedSeller}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Vendedor"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, value) => {
                                    setSelectedSupplier([]);
                                    if (value !== "All" && value !== null) {
                                        setSelectedSeller(value as string);
                                        fetchSalesSupplierMonth(value as string)
                                    }
                                    else {
                                        setSelectedSeller("All")
                                        fetchSalesSupplierMonth();
                                    }
                                }}
                            ></Autocomplete>
                            <SalesChart data={chartData} dateSelected={dateSelected} />
                        </Grid>
                    </Grid>
                </div>
            }
        </div>
    )
}
