import { Card, Checkbox } from "@mui/material"
import { DataGrid, GridColDef, esES, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from "@mui/x-data-grid";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { updateIsModifiable } from "../../../../services/productService";


function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

const ModifiableView = () => {
    const { products } = useAppSelector((state: any) => state.productReducer);
    const columns: GridColDef[] = [
        { field: "code", minWidth: 150, flex: 1, headerName: "Codigo" },
        { field: "description", minWidth: 350, flex: 1, headerName: "Descripcion" },
        {
            field: "isModifiable", minWidth: 150, flex: 1, headerName: "Modificable", renderCell: (params: { row: { _id: string, isModifiable: boolean | undefined } }) => {
                return <Checkbox checked={params.row.isModifiable} onChange={
                    async () => {
                        await updateIsModifiable(params.row._id, !params.row.isModifiable)
                    }
                } />
            }
        }]
    return (
        <div className="h-full overflow-auto">
            <Card>
                <DataGrid
                    sx={{ padding: 2 }}
                    autoHeight={true}
                    columns={columns}
                    rows={products}
                    pageSize={20}
                    getRowId={(row) => row._id}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{ Toolbar: CustomToolbar }}
                />
            </Card>
        </div>
    )
}

export default ModifiableView