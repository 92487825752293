import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Chip,
} from '@mui/material';
import { UploadFile } from '@mui/icons-material';
import {
  fetchProductListFiles,
  uploadProductListFile,
} from '../../../../services/productService';
import store from '../../../../store';
import { ProductListFileActions } from '../../../../reducers/ProductListFileReducer';

interface NewFileModalProps {
  open: boolean;
  handleClose: () => void;
}

export const NewFileModal: React.FC<NewFileModalProps> = ({
  open,
  handleClose,
}) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');

  const fileSelectedHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleButtonClick = () => {
    setTimeout(() => {
      store.dispatch({ type: ProductListFileActions.ClearError, payload: {} });
      store.dispatch({
        type: ProductListFileActions.ClearSuccess,
        payload: {},
      });
    }, 20000);
  };
  const handleAssign = async () => {
    setIsUploadingFile(true);
    await uploadProductListFile(selectedFile, fileName);
    setSelectedFile(null);
    setIsUploadingFile(false);
    handleClose();
    await fetchProductListFiles();
    handleButtonClick();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isUploadingFile) handleClose();
        else return;
      }}
      sx={{
        '& .MuiDialog-paper': {
          width: '30rem',
        },
      }}
    >
      <>
        <DialogTitle>Nuevo archivo</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              marginBottom: '1rem',
            }}
          >
            Selecciona el archivo que deseas subir
          </DialogContentText>
          <Button
            variant='outlined'
            component='label'
            sx={{
              marginBottom: '1rem',
            }}
            endIcon={<UploadFile />}
          >
            Cargar Archivo
            <input
              type='file'
              accept='application/pdf'
              hidden
              onChange={fileSelectedHandler}
            />
          </Button>
          <div>
            {selectedFile && (
              <Chip
                label={selectedFile.name}
                color='success'
                sx={{
                  marginBottom: '1rem',
                  maxWidth: '40%',
                }}
              />
            )}
          </div>
          <TextField
            label='Nombre del archivo'
            variant='outlined'
            fullWidth
            sx={{
              marginBottom: '1rem',
            }}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
          />

          <DialogActions>
            <Button
              variant='contained'
              color='error'
              disabled={isUploadingFile}
              onClick={() => {
                setSelectedFile(null);
                handleClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              color='success'
              onClick={handleAssign}
              disabled={
                selectedFile === null || fileName === '' || isUploadingFile
              }
            >
              Subir
            </Button>
          </DialogActions>
          {isUploadingFile && (
            <Typography
              variant='body2'
              sx={{
                fontStyle: 'italic',
                color: 'red',
              }}
            >
              Subiendo archivo, esto puede tardar unos minutos...
            </Typography>
          )}
        </DialogContent>
      </>
    </Dialog>
  );
};
