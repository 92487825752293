import axios from "./client";
import { CustomerActions } from "../reducers/CustomerReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchCustomers = async () => {
  store.dispatch({ type: CustomerActions.RequestCustomers, payload: {} });
  const { data: customers } = await axios.get<Customer[]>(
    `${API_URL}customers`
  );
  store.dispatch({
    type: CustomerActions.ReceiveCustomers,
    payload: customers,
  });
};

export const fetchCustomersBySupervisor = async (supervisorId: string) => {
  store.dispatch({ type: CustomerActions.RequestCustomers, payload: {} });
  const { data: customers } = await axios.get<Customer[]>(
    `${API_URL}customers/${supervisorId}/supervisor`
  );
  store.dispatch({
    type: CustomerActions.ReceiveCustomers,
    payload: customers,
  });
};

export const upsertCustomers = async (customers: Partial<Customer>[]) => {
  store.dispatch({ type: CustomerActions.RequestCustomers, payload: {} });
  await axios
    .post<Customer[] | any>(`${API_URL}customers/massive`, customers, {
      validateStatus: function (status) {
        return status <= 500; // Resolve only if the status code is less than 500
      },
    })
    .then((res) => {
      if (res.status === 200) {
        store.dispatch({
          type: CustomerActions.AddCustomers,
          payload: res.data,
        });
      } else if (res.status === 400) {
        store.dispatch({
          type: CustomerActions.UpdateErrors,
          payload: res.data,
        });
      } else if (res.status === 500) {
        store.dispatch({
          type: CustomerActions.UpdateErrorMsg,
          payload: "Server error",
        });
      }
    });
};
