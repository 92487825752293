import Logo from "../../../Logo";
import { Autocomplete, Box, Button, Grid, Switch, Tab, Tabs, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import {
  fetchInvoicesSevenDays,
  generateInvoicesXls,
  syncInvoicesLaudus,
} from "../../../../services/invoiceService";
import TwoDaysAgoInvoices from "./TwoDaysAgoInvoices";
import { DownloadOutlined, SyncOutlined } from "@mui/icons-material";
import { fetchTrucks } from "../../../../services/truckService";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const InvoicesView: React.FC = () => {
  const [isLoadingReport, setisLoadingReport] = useState(false);
  const { invoices } = useAppSelector(
    (state: any) => state.invoiceReducer
  );
  const { trucks } = useAppSelector(
    (state: any) => state.truckReducer
  );
  const getClientUniqueNames = () => {
    const uniqueNames = new Set();
    invoices.forEach((invoice: Invoice) => {
      uniqueNames.add(invoice.name);
    });
    return Array.from(uniqueNames);
  };

  const [selectedClient, setSelectedClient] = useState("");
  const [truckMap, setTruckMap] = useState(new Map());
  const [selectedTruck, setSelectedTruck] = useState("");
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date(Date.now())));
  const [enabledDateFilter, setEnabledDateFilter] = useState(false);

  useEffect(() => {
    fetchTrucks();
  }
    , []);
  useEffect(() => {
    const trucksMap = new Map();
    trucks.forEach((truck: Truck) => {
      trucksMap.set(truck._id, truck.username);
    });
    setTruckMap(trucksMap);
  }, [trucks]);


  return (
    <div className="p-5 h-full overflow-auto">
      <Logo />
      <div className="text-xl mb-3 ">Facturas (último 2 días)</div>
      {isLoadingReport ? (
        <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
      ) : (
        <div className="mb-1 flex pr-7">
          <div className=" ">
            <Button
              variant="contained"
              color="primary"
              className="mb-3"
              onClick={async () => {
                setisLoadingReport(true);
                const downloadName = `${new Date(Date.now()).getTime()}`;
                await generateInvoicesXls(downloadName);
                setisLoadingReport(false);
              }}
              startIcon={<DownloadOutlined />}
              disabled={isLoadingReport}
            >
              Descargar
            </Button>
          </div>

          <div className="ml-3">
            <Button
              variant="contained"
              color="primary"
              className="mb-3"
              onClick={async () => {
                await syncInvoicesLaudus();
                await fetchInvoicesSevenDays();
              }}
              startIcon={<SyncOutlined />}
            >
              Sincronizar
            </Button>
          </div>
        </div>
      )}
      <div className="h-full flex flex-col overflow-auto">
        <Grid container direction="row"
          justifyContent="space-around"
          sx={{
            mt: 2,
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={getClientUniqueNames()}
            style={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cliente"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setSelectedClient(value as string);
              }
              else {
                setSelectedClient("");
              }
            }}
          ></Autocomplete>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Array.from(truckMap.values())}
            style={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Camión"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setSelectedTruck(value as string);
              }
              else {
                setSelectedTruck("");
              }
            }}
          ></Autocomplete>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  width: 200,
                }}
                disabled={!enabledDateFilter}
                format="DD/MM/YYYY"
                value={selectedDate}
                onChange={(newValue) => {
                  setSelectedDate(newValue!);
                }}
              />
            </LocalizationProvider>
            <Switch
              checked={enabledDateFilter}
              onChange={(event) => {
                setEnabledDateFilter(event.target.checked);
                console.log(enabledDateFilter)
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </Grid>
        <TwoDaysAgoInvoices invoices={invoicesFilter(invoices, selectedClient, selectedTruck, selectedDate, truckMap, enabledDateFilter)!} truckMap={truckMap} />
      </div>
    </div>
  );
};

function invoicesFilter(invoices: Invoice[], selectedClient: string, selectedTruck: string, selectedDate: Dayjs, truckMap: Map<string, string>, enabledDateFilter: boolean) {
  let filteredInvoices = invoices;
  if (selectedClient !== "") {
    filteredInvoices = filteredInvoices.filter((invoice: Invoice) => {
      return invoice.name === selectedClient;
    }
    );
  }
  if (selectedTruck !== "") {
    filteredInvoices = filteredInvoices.filter((invoice: Invoice) => {
      return truckMap.get(invoice.truck) === selectedTruck;
    }
    );
  }
  if (enabledDateFilter) {
    filteredInvoices = filteredInvoices.filter((invoice: Invoice) => {
      return dayjs(invoice.assignedTruckDate).isSame(selectedDate, "day") && dayjs(invoice.assignedTruckDate).isSame(selectedDate, "month") && dayjs(invoice.assignedTruckDate).isSame(selectedDate, "year") && invoice.assignedTruckDate !== undefined;
    }
    );
    console.log(filteredInvoices)
  }
  return filteredInvoices;
}

export default InvoicesView;
