import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";

import { fetchProducts } from "../../../../services/productService";
import PriceView from "./PriceView";
import StockView from "./StockView";
import Logo from "../../../Logo";
import ModifiableView from "./ModifiableView";

const ProductView: React.FC = () => {
  const { isLoading } = useAppSelector((state: any) => state.productReducer);
  const effectRan = useRef(false)

  const [tab, setTab] = useState<"Precios" | "Stock" | "Modificable">("Precios"); //define el state para el componente

  useEffect(() => {
    if (effectRan.current === false) {
      fetchProducts();
      return () => {
        effectRan.current = true
      }
    }
  }, []);

  return (
    <div className="p-5 h-full overflow-auto">
      <Logo />
      <div className="text-xl mb-3">Productos</div>
      {isLoading ? (
        <div className="flex justify-center w-full col-span-full">
          <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
        </div>
      ) : (
        <div className="h-full flex flex-col overflow-auto">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={(_, v) => {
                setTab(v as "Precios" | "Stock" | "Modificable");
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Precios" value={"Precios"} />
              <Tab label="Stock" value={"Stock"} />
              <Tab label="Modificable" value={"Modificable"} />
            </Tabs>
          </Box>
          <div className="flex-1 overflow-auto">
            {tab === "Precios" && <PriceView></PriceView>}
            {tab === "Stock" && <StockView></StockView>}
            {tab === "Modificable" && <ModifiableView />}
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductView;
