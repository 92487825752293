import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { downloadSalesFamilySellerMonthXLS, fetchSalesFamilyMonth } from '../../../../../services/statisticsService';
import { getSellersNames } from '../../../../../services/sellerService';
import FamilyFilterInput from './FamilyFilterInput';
import SalesTable from './SalesTable';
import SalesChart from './SalesChart';
import { SalesPerDate, SalesPerFamily } from '../../../../../types/Statistics';

const calculateTotals = (data: SalesPerFamily[]): SalesPerDate => {
  const totals: SalesPerDate = {};
  data.forEach((item) => {
    Object.entries(item.sales!).forEach(([key, value]) => {
      if (totals[key]) {
        totals[key] += value;
      } else {
        totals[key] = value;
      }
    });
  });
  return totals;
};

export const SalesPerFamilyView = () => {
  const [selectedSeller, setSelectedSeller] = React.useState('All');
  const { statistics, isLoading } = useAppSelector(
    (s: any) => s.statisticsReducer
  );
  const [selectedFamilies, setSelectedFamilies] = useState<string[]>([]);
  const [filterText, setFilterText] = useState('');
  const [dateSelected, setDateSelected] = useState<string[]>([]);
  const [isDownloading, setisDownloading] = useState(false);
  const chartData = statistics.filter((item: { family: string }) =>
    selectedFamilies.includes(item.family)
  );
  const handleFamilySelection = (family: string) => {
    if (selectedFamilies.includes(family)) {
      setSelectedFamilies((prev) => prev.filter((v) => v !== family));
    } else {
      setSelectedFamilies((prev) => [...prev, family]);
    }
  };
  const [sellerNames, setSellerNames] = React.useState<string[]>([]);
  const clearSelection = () => {
    setSelectedFamilies([]);
  };
  useEffect(() => {
    getSellersNames().then((data) => {
      setSellerNames(data);
    });
  }, []);
  return (
    <div>
      {isLoading ? (
        <div className='flex justify-center w-full col-span-full'>
          <Icon spin path={mdiCircleSlice1} className='w-7'></Icon>
          Por favor espere...
        </div>
      ) : (
        <div className='flex-1 overflow-auto'>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
            paddingLeft={2}
            paddingRight={2}
            maxWidth={'auto'}
          >
            <Grid md={6} item>
              <SalesTable
                data={statistics}
                filterText={filterText}
                selectedFamilies={selectedFamilies}
                handleFamilySelection={handleFamilySelection}
                dateSelected={dateSelected}
                setDateSelected={setDateSelected}
                totals={calculateTotals(statistics)}
              />
            </Grid>
            <Grid md={6} sx={{ paddingTop: 1 }} item>
              <Grid container spacing={1} direction={'row'}>
                <Grid item>
                  <FamilyFilterInput onFilterChange={setFilterText} />
                </Grid>
                <Grid item>
                  <Button
                    sx={{ marginLeft: 2 }}
                    variant='contained'
                    disabled={selectedFamilies.length === 0}
                    onClick={() => {
                      clearSelection();
                      setSelectedSeller('All');
                      fetchSalesFamilyMonth();
                      setFilterText('');
                    }}
                  >
                    Limpiar selección
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    onClick={async () => {
                      setisDownloading(true);
                      await downloadSalesFamilySellerMonthXLS("Ventas_familia")
                      setisDownloading(false);
                    }}
                    disabled={isDownloading}
                  >

                    Descargar reporte
                  </Button>
                </Grid>
              </Grid>
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                options={[...sellerNames, 'All']}
                style={{ width: 300 }}
                value={selectedSeller}
                renderInput={(params) => (
                  <TextField {...params} label='Vendedor' variant='outlined' />
                )}
                onChange={(event, value) => {
                  setSelectedFamilies([]);
                  if (value !== 'All' && value !== null) {
                    setSelectedSeller(value as string);
                    fetchSalesFamilyMonth(value as string);
                  } else {
                    setSelectedSeller('All');
                    fetchSalesFamilyMonth();
                  }
                }}
              ></Autocomplete>

              <SalesChart data={chartData} dateSelected={dateSelected} totals={calculateTotals(statistics)} />

            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};
