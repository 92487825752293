import React from 'react';
import { DeletedOrdersViewManager } from '../../Common/Order/DeletedOrdersView';

const DeletedOrdersView: React.FC = () => {
  return (
    <div>
      <DeletedOrdersViewManager /> {}
    </div>
  );
};

export default DeletedOrdersView;
