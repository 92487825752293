interface State {
    invoices: Invoice[];
    isLoading: boolean;
}

const initialState: State = {
    invoices: [],
    isLoading: false,
};

export enum InvoiceActions {
    RequestInvoices = "REQUEST_INVOICES",
    ReceiveInvoices = "RECEIVE_INVOICES",
    StopSyncInvoices = "STOP_SYNC_INVOICES",
}

const reducer = (
    state: State = initialState,
    { type, payload }: { type: InvoiceActions; payload: any }
): State => {
    switch (type) {
        case InvoiceActions.RequestInvoices:
            return { ...state, isLoading: true };
        case InvoiceActions.ReceiveInvoices:
            return { ...state, invoices: payload, isLoading: false };
        case InvoiceActions.StopSyncInvoices:
            return { ...state, isLoading: false };
        default:
            return state;
    }
};

export default reducer;

