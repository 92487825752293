interface State {
  products: Product[];
  isLoading: boolean;
  errorProducts: ErrorProduct[];
  errorMsg: string;
  success: boolean;
}

const initialState: State = {
  products: [],
  isLoading: false,
  errorProducts: [],
  errorMsg: "",
  success: false,
};

export enum ProductActions {
  RequestProducts = "REQUEST_PRODUCTS",
  ReceiveProducts = "RECEIVE_PRODUCTS",
  UpdateProduct = "UPDATE_PRODUCT",
  AddProducts = "ADD_PRODUCTS",
  UpdateProducts = "UPDATE_PRODUCTS",
  UpdateErrors = "UPDATE_ERRORS",
  UpdateErrorMsg = "UPDATE_ERROR_MSJ",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: ProductActions; payload: any }
): State => {
  switch (type) {
    case ProductActions.RequestProducts: //hago el llamado para que la api me traiga los producots
      return {
        ...state,
        isLoading: true,
        success: false,
        errorMsg: "",
        errorProducts: [],
      };
    case ProductActions.ReceiveProducts: //recibo el arreglo de productos
      return { ...state, products: payload, isLoading: false };
    case ProductActions.AddProducts: //agrego un producto
      return {
        ...state,
        isLoading: false,
        success: true,
        products: [
          ...state.products.filter((p) => {
            return !payload.some((f: any) => {
              return f._id === p._id;
            });
          }),
          ...payload,
        ],
      };
    case ProductActions.UpdateProducts:
      return {
        ...state,
        isLoading: false,
        success: true,
        products: payload,
      };
      case ProductActions.UpdateProduct:
        const updatedProductIndex = state.products.findIndex(p => p._id === payload._id);
        if (updatedProductIndex !== -1) {
          const updatedProducts = [...state.products];
          updatedProducts[updatedProductIndex] = payload;
          return {
            ...state,
            isLoading: false,
            success: true,
            products: updatedProducts,
          };
        } else {
          // Si no se encuentra el producto en el arreglo, simplemente agregamos el nuevo producto al final.
          return {
            ...state,
            isLoading: false,
            success: true,
            products: [...state.products, payload],
          };
        }
    case ProductActions.UpdateErrors:
      return {
        ...state,
        errorProducts: payload,
        isLoading: false,
      };
    case ProductActions.UpdateErrorMsg:
      return {
        ...state,
        errorMsg: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
