import React from 'react';
import { TextField } from '@mui/material';

interface ProductFilterInputProps {
    onFilterChange: (filterText: string) => void;
}

const ProductFilterInput: React.FC<ProductFilterInputProps> = ({ onFilterChange }) => {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange(event.target.value);
    };

    return (
        <TextField
            label="Filtrar SKU"
            variant="outlined"
            size="medium"
            style={{ minWidth: 300 }}
            onChange={handleInputChange}
        />
    );
};

export default ProductFilterInput;
