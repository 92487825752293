import axios from "./client";
import FileDownload from "js-file-download";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchGeoVisitsBySeller = async (sellerId: string) => {
    try {
    const { data: geoVisits } = await axios.get<GeoVisit[]>(
        `${API_URL}geoVisits/${sellerId}`
    );
    return geoVisits;
    } catch (error) {
    alert("Error al obtener las visitas");
    return [];
}
}

export const getGeoVisitsXls = async () => {
    await axios
    .get(API_URL + `geoVisits/report`, {
        responseType: "blob",
        headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
        },
    })
    .then((res) => {
        FileDownload(res.data, `geoVisits.xlsx`);
    });
}
