import Logo from "../../../Logo";
import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";

import CustomerReportView from "./CustomerReportView";
import SellerReportView from "./SellerReportView";
import { fetchCustomers } from "../../../../services/customerService";
import { fetchSellers } from "../../../../services/sellerService";
import { OrdersReportView } from "../../Common/OrdersReport/OrdersReportView";
import { fetchOrders } from "../../../../services/orderService";
import SellerDayReportView from "./SellerDayReportView";
import VisitReportView from "./VisitReportView";
import GeoVisitView from "../../Common/GeoVisits/GeoVisitView";

const ReportsView: React.FC = () => {
  const isLoadingSeller = useAppSelector((s: any) => s.sellerReducer).isLoading;
  const isLoadingCustomer = useAppSelector(
    (s: any) => s.customerReducer
  ).isLoading;

  const [tab, setTab] = useState<
    "client" | "seller" | "orders" | "sellerDay" | "visits" | "geo"
  >("client"); //define el state para el componente
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      fetchCustomers();
      fetchSellers();
      fetchOrders();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  return (
    <div className="p-5 h-full overflow-auto">
      <Logo />
      <div className="text-xl mb-3">Reportes</div>
      {isLoadingSeller || isLoadingCustomer ? (
        <div className="flex justify-center w-full col-span-full">
          <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
        </div>
      ) : (
        <div className="h-full flex flex-col overflow-auto">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={(_, v) => {
                setTab(v as "client" | "seller" | "orders" | "sellerDay" | "geo" | "visits");
              }}
              aria-label="basic tabs example"
            >
              <Tab label="Por cliente" value={"client"} />
              <Tab label="Por vendedor" value={"seller"} />
              <Tab label="Por orden" value={"orders"} />
              <Tab label="Vendedor por día" value={"sellerDay"} />
              <Tab label="Visitas a cliente" value={"visits"} />
              <Tab label="Geolocalizacion" value={"geo"} />
            </Tabs>
          </Box>
          <div className="flex-1 overflow-auto">
            {tab === "seller" && <SellerReportView />}
            {tab === "client" && <CustomerReportView />}
            {tab === "orders" && <OrdersReportView />}
            {tab === "sellerDay" && <SellerDayReportView />}
            {tab === "visits" && <VisitReportView />}
            {tab === "geo" && <GeoVisitView />}

          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsView;
