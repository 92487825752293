import React from 'react'
import { CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { SalesPerBrand, SalesPerDate } from '../../../../../types/Statistics';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';

const transformDataForChart = (data: SalesPerBrand[], dateSelected: string[]): any[] => {
    const chartData: any = {};
    data.forEach(familyData => {
        Object.entries(familyData.sales).forEach(([month, sales]) => {
            if (dateSelected.includes(month)) { // Verifica si el mes está en dateSelected
                if (!chartData[month]) {
                    chartData[month] = { month };
                }
                chartData[month][familyData.brand] = sales;
            }
        });

    });

    return Object.values(chartData);
};

const generateColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 100%, 70%)`;
};

const SalesChart: React.FC<{ data: SalesPerBrand[], totals: SalesPerDate, dateSelected: string[] }> = ({ data, totals, dateSelected }) => {
    const chartData = transformDataForChart(data, dateSelected).sort((a, b) => Date.parse(a.month) - Date.parse(b.month));

    const dataWithColors = data.map(familyData => ({
        ...familyData,
        color: generateColor()
    }));

    return (
        <ResponsiveContainer width="100%" height={600}>
            <LineChart data={chartData}>
                <CartesianGrid />
                <XAxis dataKey="month" />
                <YAxis
                    tickFormatter={(value) =>
                        new Intl.NumberFormat("es-CL", {
                            notation: "compact",
                            compactDisplay: "short",
                        }).format(value)
                    }
                />
                <Tooltip
                    formatter={(value, name, props) => {
                        const percentage = (parseFloat(value.toString()) / totals[props.payload.month]) * 100;
                        return `${formatChileanLocale(value as number)} CLP - P: ${percentage.toFixed(2)}% `
                    }}
                />
                <Legend />
                {dataWithColors.map(familyData => (
                    <Line
                        strokeWidth={4}
                        key={familyData.brand}
                        type="monotone"
                        dataKey={familyData.brand}
                        stroke={familyData.color}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SalesChart;
