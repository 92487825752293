interface State {
  token?: string;
  user?: User;
}

const initialState: State = {};

export enum AuthActions {
  SetSession = "SET_SESSION",
  DelSession = "DEL_SESSION",
}
// el initial state se pone para empezar los estados, las acciones son lo que modifican los estados
const reducer = (
  state: State = initialState,
  { type, payload }: { type: AuthActions; payload: any }
): State => {
  switch (type) {
    case AuthActions.SetSession:
      return { ...state, token: payload.token, user: payload.user };
    case AuthActions.DelSession:
      return { ...state, token: undefined, user: undefined };
    default:
      return state;
  }
};

export default reducer;
