import { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import { downloadSalesSellerMonthXLS, fetchSalesSellerMonth } from '../../../../../services/statisticsService';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import SalesTable from './SalesTable';
import SellerFilterInput from './SellerFilterInput';
import SalesChart from './SalesChart';
import { SalesPerDate, SalesPerSeller } from '../../../../../types/Statistics';

export const SalesPerSellerView = () => {
  const [selectedBranch, setSelectedBranch] = useState('All');
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const [filterText, setFilterText] = useState('');
  const [dateSelected, setDateSelected] = useState<string[]>([]);
  const [showTotalsInChart, setShowTotalsInChart] = useState(false);
  const [isDownloading, setisDownloading] = useState(false);
  const { statistics, isLoading } = useAppSelector(
    (s: any) => s.statisticsReducer
  );
  let chartData = statistics.filter((item: { seller: string }) =>
    selectedVendors.includes(item.seller)
  );
  const handleVendorSelection = (seller: string) => {
    if (selectedVendors.includes(seller)) {
      setSelectedVendors((prev) => prev.filter((v) => v !== seller));
    } else {
      setSelectedVendors((prev) => [...prev, seller]);
    }
  };
  const clearSelection = () => {
    setSelectedVendors([]);
  };
  if (showTotalsInChart) {
    chartData = [
      ...chartData,
      { seller: 'Totales', sales: calculateTotals(statistics) },
    ];
  }
  return (
    <div>
      {isLoading ? (
        <div className='flex justify-center w-full col-span-full'>
          <Icon spin path={mdiCircleSlice1} className='w-7' />
          Por favor espere...
        </div>
      ) : (
        <div>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
            paddingLeft={2}
            paddingRight={2}
            maxWidth={'auto'}
          >
            <Grid md={6} item>
              <SalesTable
                data={statistics}
                filterText={filterText}
                selectedVendors={selectedVendors}
                handleVendorSelection={handleVendorSelection}
                showTotalsInChart={showTotalsInChart}
                setShowTotalsInChart={setShowTotalsInChart}
                dateSelected={dateSelected}
                setDateSelected={setDateSelected}
              />
            </Grid>
            <Grid md={6} item>
              <Grid container spacing={1} direction={'row'}>
                <Grid item>
                  <SellerFilterInput onFilterChange={setFilterText} />
                </Grid>
                <Grid item>
                  <Button
                    sx={{ marginLeft: 2 }}
                    variant='contained'
                    disabled={selectedVendors.length === 0}
                    onClick={() => {
                      clearSelection();
                      setSelectedBranch('All');
                      fetchSalesSellerMonth();
                      setFilterText('');
                    }}
                  >
                    Limpiar selección
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant='contained'
                    onClick={async () => {
                      setisDownloading(true);
                      await downloadSalesSellerMonthXLS("Ventas_vendedor")
                      setisDownloading(false);
                    }}
                    disabled={isDownloading}
                  >

                    Descargar reporte
                  </Button>
                </Grid>
              </Grid>
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                options={[
                  'ZONA SUR',
                  'SANTIAGO ARAUCO 960',
                  'SAN ANTONIO',
                  'All',
                ]}
                style={{ width: 300 }}
                value={selectedBranch}
                renderInput={(params) => (
                  <TextField {...params} label='Sucursal' variant='outlined' />
                )}
                onChange={(event, value) => {
                  setSelectedVendors([]);
                  if (value !== 'All' && value !== null) {
                    setSelectedBranch(value as string);
                    fetchSalesSellerMonth(value as string);
                  } else {
                    setSelectedBranch('All');
                    fetchSalesSellerMonth();
                  }
                }}
              ></Autocomplete>
              <SalesChart data={chartData} dateSelected={dateSelected} />
            </Grid>
          </Grid>
        </div>
      )}
    </div >
  );
};
const calculateTotals = (data: SalesPerSeller[]): SalesPerDate => {
  const totals: SalesPerDate = {};

  data.forEach((item) => {
    Object.entries(item.sales).forEach(([key, value]) => {
      if (totals[key]) {
        totals[key] += value;
      } else {
        totals[key] = value;
      }
    });
  });

  return totals;
};
