import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useForm } from "../../../../hooks/useForm";
import { addSupervisor, updateSupervisor } from "../../../../services/supervisorService";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { fetchSellers } from "../../../../services/sellerService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    supervisor: Supervisor | null;
    setSupervisor: (supervisor: Supervisor | null) => void;
}

const NewSupervisorModal: React.FC<Props> = ({
    open,
    onClose,
    supervisor,
    setSupervisor,
}) => {
    const initialValues = {
        name: "",
        username: "",
        subordinates: [] as string[],
    };
    const [values, setValues, handleChange] = useForm(initialValues);
    const sellers = useAppSelector((state: any) => state.sellerReducer.sellers); //esto es para el modal
    useEffect(() => {
        if (supervisor) {
            setValues({
                ...values,
                name: supervisor.name,
                username: supervisor.username,
                subordinates: supervisor.subordinates,
            });
        } else {
            setValues(initialValues);
        }
    }, [supervisor]);

    useEffect(() => {
        fetchSellers();
    }, []);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onTransitionEnd={() => !open && setValues(initialValues)} //para borrar los values solo cuando se cierra
        >
            <DialogTitle>Nuevo Supervisor</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <TextField
                        disabled={!!supervisor} //si hay un seller, es porque estoy editando, y no quiero que se pueda editar el nombre
                        onChange={handleChange}
                        id="username"
                        label="Usuario"
                        value={values.username}
                    ></TextField>
                    <TextField
                        onChange={handleChange}
                        id="name"
                        label="Nombre"
                        value={values.name}
                    ></TextField>
                    <FormControl>
                        <InputLabel id="priceList-label">Vendedores</InputLabel>
                        <Select
                            labelId="priceList-label"
                            id="priceList"
                            multiple
                            value={values.subordinates}
                            label="Lista de Precio"
                            onChange={(e) => {
                                setValues({ ...values, subordinates: e.target.value as string[] });
                            }}
                        >
                            {sellers.map((seller: Seller) => (
                                <MenuItem key={seller._id} value={seller._id}>
                                    {seller.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (supervisor !== null) {
                            setSupervisor(null);
                            setValues(initialValues);
                        }
                        onClose();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        values.username === "" ||
                        values.name === "" ||
                        values.subordinates.length === 0
                    }
                    onClick={async () => {
                        if (supervisor) {
                            await updateSupervisor(supervisor._id, values);
                            setSupervisor(null);
                            onClose();
                        } else {
                            await addSupervisor(values);
                            setSupervisor(null);
                            onClose();
                        }
                    }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewSupervisorModal;
