import axios from "./client";
import { InvoiceActions } from "../reducers/InvoiceReducer";
import store from "./../store";
import FileDownload from "js-file-download";

const API_URL = process.env.REACT_APP_API_LOGISTICS_URL;

export const fetchInvoicesSevenDays = async () => {
  store.dispatch({ type: InvoiceActions.RequestInvoices, payload: {} });
  const { data: invoices } = await axios.get<Invoice[]>(`${API_URL}invoices`);
  store.dispatch({
    type: InvoiceActions.ReceiveInvoices,
    payload: invoices,
  });
};

export const syncInvoicesLaudus = async () => {
  store.dispatch({ type: InvoiceActions.RequestInvoices, payload: {} });
  await axios.get(`${API_URL}invoices/syncLaudus`);
  store.dispatch({ type: InvoiceActions.StopSyncInvoices, payload: {} });
};

export const updateSentWhatsappInvoice = async (
  invoiceId: string,
  value: boolean
) => {
  store.dispatch({ type: InvoiceActions.RequestInvoices, payload: {} });
  const bodyData = { sentToWhatsApp: value };
  await axios.patch(
    `${API_URL}invoices/${invoiceId}`,
    JSON.stringify(bodyData),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const { data: invoices } = await axios.get<Invoice[]>(`${API_URL}invoices`);
  store.dispatch({
    type: InvoiceActions.ReceiveInvoices,
    payload: invoices,
  });
};
export const generateInvoicesXls = async (downloadName: string) => {
  await axios
    .get(API_URL + `invoices/xls/report`, {
      responseType: "blob",
      headers: {
        "content-type": "application/vnd.ms-excel;charset=UTF-8",
      },
    })
    .then((res) => {
      FileDownload(res.data, `${downloadName}.xlsx`);
    });
};

export const getPdfUrl = async (invoiceId: string) => {
  const { data: pdfUrl } = await axios.get<string>(
    `${API_URL}invoices/${invoiceId}/pdf`
  );
  return pdfUrl;
};
