import axios from "./client";
import { QuotationActions } from "../reducers/QuotationReducer";
import store from "./../store";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchQuotations = async () => {
  store.dispatch({ type: QuotationActions.RequestQuotations, payload: {} });
  const { data: quotations } = await axios.get<Quotation[]>(
    `${API_URL}quotations`
  );
  store.dispatch({
    type: QuotationActions.ReceiveQuotations,
    payload: quotations,
  });
};
