import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { SalesPerSeller } from '../../../../../types/Statistics';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';

const transformDataForChart = (
  data: SalesPerSeller[],
  dateSelected: string[]
): any[] => {
  const chartData: any = {};
  data.forEach((vendedorData) => {
    Object.entries(vendedorData.sales).forEach(([month, sales]) => {
      if (dateSelected.includes(month)) {
        // Verifica si el mes está en dateSelected
        if (!chartData[month]) {
          chartData[month] = { month };
        }
        chartData[month][vendedorData.seller] = sales;
      }
    });
  });

  return Object.values(chartData);
};
const generateColor = () => {
  const hue = Math.floor(Math.random() * 360);
  return `hsl(${hue}, 100%, 70%)`; // Usamos HSL para variar el tono manteniendo la saturación y luminosidad constantes
};

const SalesChart: React.FC<{
  data: SalesPerSeller[];
  dateSelected: string[];
}> = ({ data, dateSelected }) => {
  const chartData = transformDataForChart(data, dateSelected).sort(
    (a, b) => Date.parse(a.month) - Date.parse(b.month)
  );
  const dataWithColors = data.map((vendedorData) => ({
    ...vendedorData,
    color: generateColor(),
  }));

  return (
    <ResponsiveContainer width='100%' height={600}>
      <LineChart data={chartData}>
        <CartesianGrid stroke='#ccc' />
        <XAxis dataKey='month' />
        <YAxis
          tickFormatter={(value) =>
            new Intl.NumberFormat('es-CL', {
              notation: 'compact',
              compactDisplay: 'short',
            }).format(value)
          }
        />
        <Tooltip formatter={(value) => formatChileanLocale(value as number)} />
        <Legend />
        {dataWithColors.map((vendedorData) => (
          <Line
            strokeWidth={4}
            key={vendedorData.seller}
            type='monotone'
            dataKey={vendedorData.seller}
            stroke={vendedorData.color}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SalesChart;
