import { useEffect, useState } from 'react'
import { useAppSelector } from "../../../../hooks/storeHooks";
import {
    APIProvider,
    Map,
    Marker,
} from '@vis.gl/react-google-maps';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { fetchGeoVisitsBySeller, getGeoVisitsXls } from '../../../../services/geoVisitService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ComboBox from '../../../Combobox';



const GeoVisitView = () => {
    const { isLoading, sellers } = useAppSelector((state) => state.sellerReducer)
    const { isLoading: isLoadingCustomers, customers } = useAppSelector((state) => state.customerReducer)
    const [markers, setMarkers] = useState<GeoVisit[]>([]);
    const [filteredMarkers, setFilteredMarkers] = useState<GeoVisit[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [selectedSeller, setSelectedSeller] = useState<Seller | null>(null);


    useEffect(() => {
        if (selectedSeller) {
            fetchGeoVisitsBySeller(selectedSeller._id).then((data) => {
                setMarkers(data);
            });
        }
    }, [selectedSeller]);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = markers;
            if (startDate) {
                filtered = filtered.filter(marker => new Date(marker.date) >= startDate);
            }
            if (endDate) {
                filtered = filtered.filter(marker => new Date(marker.date) <= endDate);
            }
            if (selectedCustomer) {
                filtered = filtered.filter(marker => marker.customer === selectedCustomer._id);
            }
            setFilteredMarkers(filtered);
        };
        applyFilters();
    }, [startDate, endDate, selectedCustomer, markers]);

    return (
        <div className="p-5 h-full overflow-auto">
            <div className="text-xl mb-3">Mapas de recorrido</div>
            <div className='flex justify-between mb-1'>
                <div> Q Visitas: {filteredMarkers.length} </div>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        getGeoVisitsXls()
                    }
                    }
                >
                    Descargar Reporte
                </Button>
            </div>
            {isLoading && isLoadingCustomers ? <div>Cargando...</div> : (<div className='flex gap-2'>
                <FormControl
                    fullWidth>
                    <InputLabel id="seller-select">Vendedor</InputLabel>
                    <Select
                        labelId="seller-select"
                        id='seller-select'
                        className="mb-3"
                        fullWidth
                        label="Vendedor"
                        onChange={(e) => {
                            const fullSeller = sellers.find((seller) => seller._id === e.target.value);
                            setSelectedSeller(fullSeller!);
                        }}
                        value={selectedSeller?.name}
                    >
                        {sellers.map((seller: Seller) => (
                            <MenuItem key={seller._id} value={seller._id}>{seller.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de inicio"
                        value={startDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 0 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(0);
                                newValue.setMinutes(0);
                            }
                            setStartDate(newValue)
                        }}
                    />
                    <DatePicker
                        sx={{ minWidth: '200px' }}
                        format='dd/MM/yyyy'
                        label="Fecha de fin"
                        value={endDate}
                        onChange={(newValue) => {
                            // set hours and minutes to 23:59 to avoid problems with the time
                            if (newValue) {
                                newValue.setHours(23);
                                newValue.setMinutes(59);
                            }
                            setEndDate(newValue)
                        }}
                    />
                </LocalizationProvider>
                <ComboBox<Customer>
                    value={selectedCustomer as Customer}
                    selectValue="name"
                    onChange={(customer: Customer) => {
                        setSelectedCustomer(customer);
                    }}
                    options={customers.filter
                        (customer => customer.sellerIdLaudus === selectedSeller?.laudusId)
                    }
                    label="Clientes"
                ></ComboBox>
            </div>)}
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!} libraries={['marker']}>
                <Map
                    style={{ width: '90%', height: '100%' }}
                    mapId={'bf51a910020fa25a'}
                    defaultZoom={5}
                    defaultCenter={{ lat: -33, lng: -70 }}
                    gestureHandling={'greedy'}
                    disableDefaultUI>
                    {filteredMarkers.map((marker) => (
                        <Marker
                            key={marker._id}
                            position={{ lat: marker.latitude, lng: marker.longitude }}
                        />
                    ))}
                </Map>
            </APIProvider>
        </div>
    )
}

export default GeoVisitView