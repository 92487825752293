import axios from "./client";
import { SupervisorActions } from "../reducers/SupervisorReducer";
import store from "./../store";
import FileDownload from "js-file-download";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchSupervisors = async () => {
    store.dispatch({ type: SupervisorActions.RequestSupervisors, payload: {} });
    const { data: supervisors } = await axios.get<Supervisor[]>(`${API_URL}supervisors`);
    store.dispatch({
        type: SupervisorActions.ReceiveSupervisors,
        payload: supervisors,
    });
};
export const deleteSupervisor = async (id: string) => {
    const { data: supervisor } = await axios.delete<Supervisor>(
        `${API_URL}supervisors/${id}`
    );
    store.dispatch({
        type: SupervisorActions.DeleteSupervisor,
        payload: supervisor,
    });
};
export const updateSupervisor = async (id: string, supervisor: Partial<Supervisor>) => {
    const { data: editedSupervisor } = await axios.patch<Supervisor>(
        `${API_URL}supervisors/${id}`,
        supervisor
    );
    store.dispatch({
        type: SupervisorActions.AddSupervisor,
        payload: editedSupervisor,
    });
}
export const addSupervisor = async (supervisor: Partial<Supervisor>) => {
    const { data: newSupervisor } = await axios.post<Supervisor>(
        `${API_URL}supervisors/`,
        supervisor
    );
    store.dispatch({
        type: SupervisorActions.AddSupervisor,
        payload: newSupervisor,
    });
};

export const generateSupervisorXls = async (id: string, downloadName: string) => {
    await axios
        .get(API_URL + `supervisors/${id}/xls/report`, {
            responseType: "blob",
            headers: {
                "content-type": "application/vnd.ms-excel;charset=UTF-8",
            },
        })
        .then((res) => {
            FileDownload(res.data, `${downloadName}.xlsx`);
        });
}
export const changePassword = async (body: {
    username: string;
    password: string;
}) => {
    try {
        const { data: success } = await axios.post<boolean>(
            `${API_URL}supervisors/passwordchange`,
            body
        );
        return success;
    } catch (error) {
        console.log(error);
        return false;
    }
};