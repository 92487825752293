import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { GridRow, SalesPerDate, SalesPerProduct } from '../../../../../types/Statistics'
import { Card, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';

const transformData = (data: SalesPerProduct[],
    columns: string[],
): GridRow[] => {
    // const lastMonth = columns[columns.length - 3];
    return data
        .map((item, index) => ({
            id: index,
            product: item.product,
            description: item.description,
            ...item.quantity,
            ['Promedio 3 meses atrás']: averageLast3Months({
                id: index,
                product: item.product,
                ...item.quantity
            }, columns)
        }))
        .sort((a, b) => {
            if (a['Promedio 3 meses atrás'] > b['Promedio 3 meses atrás']) {
                return -1;
            }
            else if (a['Promedio 3 meses atrás'] < b['Promedio 3 meses atrás']) {
                return 1;
            }
            else {
                return 0;
            }
        });
};

const averageLast3Months = (data: GridRow, columns: string[]): number => {
    const last3Months = columns.slice(columns.length - 4, columns.length - 1);
    const average = last3Months.map(month => {
        if (data[month] === undefined) {
            return 0;
        }
        return data[month] as number
    }
    ).reduce((a, b) => a + b, 0) / last3Months.length;

    return average;

};

const getAllColumns = (data: SalesPerProduct[]): string[] => {
    const allColumns: Set<string> = new Set();
    data.forEach(item => {
        Object.keys(item.sales).forEach(column => {
            allColumns.add(column);
        });
    });
    allColumns.add('Promedio 3 meses atrás');
    return Array.from(allColumns).sort();
};

export const QuantityTable: React.FC<{
    filterText: string,
    data: SalesPerProduct[],
}> = ({
    data,
    filterText,
}) => {
        const [rows, setRows] = useState<GridRow[]>([]);
        const [allColumns, setAllColumns] = useState<string[]>([]);
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(500);

        const handleChangePage = useCallback((event: unknown, newPage: number) => {
            setPage(newPage);
        }, []);

        const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        }, []);

        useEffect(() => {
            // Actualiza allColumns cuando data cambia
            setAllColumns(getAllColumns(data));
        }, [data]);

        useEffect(() => {
            // Actualiza rows cuando allColumns y totals cambian
            setRows(transformData(data, allColumns));
        }, [allColumns]);

        const filteredRows = useMemo(() => {
            return rows.filter(row => row.product?.toLowerCase().includes(filterText.toLowerCase()) || row.description?.toLowerCase().includes(filterText.toLowerCase()));
        }, [filterText, rows]);

        return (
            <div>
                <Card style={{ border: "solid", borderColor: "grey", maxWidth: 1000 }}  >
                    <div>
                        <TableContainer component={Paper} style={{ overflow: 'auto', maxHeight: 600 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ position: 'sticky', left: 0, zIndex: 1 }}>Código</TableCell>
                                        <TableCell >Descripción</TableCell>
                                        {allColumns.map((column) => (
                                            <TableCell key={column}>{column}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell className='cell-columna' style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}>{row.product}</TableCell>
                                            <TableCell className='cell-columna' >{row.description}</TableCell>
                                            {allColumns.map((column) => {
                                                if (column === 'Promedio 3 meses atrás') {
                                                    return <TableCell className='cell-columna' style={{ color: 'green' }} key={column}>{typeof row[column] === 'number' ? `${formatChileanLocale(row[column] as number)}` : '-'}</TableCell>;
                                                }
                                                return <TableCell className='cell-columna' key={column}>{typeof row[column] === 'number' ? `${formatChileanLocale(row[column] as number)}` : '-'}</TableCell>;
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Card>
            </div>
        )
    }

