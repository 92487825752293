import * as React from 'react';
import './VentasDataGrid.css';
import {
  GridRow,
  SalesPerCustomer,
  SalesPerDate,
} from '../../../../../types/Statistics';
import {
  Card,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { formatChileanLocale } from '../../../../../utils/formatChileanLocale';

const transformData = (data: SalesPerCustomer[]): GridRow[] => {
  return data.map((item, index) => ({
    id: index,
    customer: item.customer,
    ...item.sales,
  }));
};

const getAllColumns = (data: SalesPerCustomer[]): string[] => {
  const allColumns: Set<string> = new Set();
  data.forEach((item) => {
    Object.keys(item.sales).forEach((column) => {
      allColumns.add(column);
    });
  });
  return Array.from(allColumns).sort(); // Ordenar las columnas cronológicamente
};

const calculateTotals = (data: SalesPerCustomer[]): SalesPerDate => {
  const totals: SalesPerDate = {};

  data.forEach((item) => {
    Object.entries(item.sales).forEach(([key, value]) => {
      if (totals[key]) {
        totals[key] += value;
      } else {
        totals[key] = value;
      }
    });
  });

  return totals;
};
const SalesTable: React.FC<{
  data: SalesPerCustomer[];
  filterText: string;
  selectedClients: any;
  handleClientSelection: (cliente: string) => void;
  dateSelected: string[];
  setDateSelected: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ data, filterText, selectedClients, handleClientSelection, dateSelected, setDateSelected }) => {
  const rows = transformData(data);
  const allColumns = getAllColumns(data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totals = calculateTotals(data);
  const [sortColumn, setSortColumn] = useState(''); // Estado para rastrear la columna de ordenación
  const [sortDescending, setSortDescending] = useState(false); // Estado para rastrear la dirección de ordenación
  const [filteredRows, setFilteredRows] = useState<GridRow[]>(
    rows.filter((row) =>
      row.customer!.toLowerCase().includes(filterText.toLowerCase())
    )
  ); // Estado para rastrear las filas filtradas y ordenadas

  // Función para filtrar y ordenar las filas según la paginación
  const filterAndSortRows = () => {
    // Filtrar las filas según el texto de filtro
    let filtered = rows.filter((row) =>
      row.customer!.toLowerCase().includes(filterText.toLowerCase())
    );

    // Ordenar las filas si se ha seleccionado una columna de ordenación
    if (sortColumn) {
      filtered = [...filtered].sort((a, b) => {
        const aValue: any =
          typeof a[sortColumn] === 'number' ? a[sortColumn] : 0;
        const bValue: any =
          typeof b[sortColumn] === 'number' ? b[sortColumn] : 0;

        return sortDescending ? bValue - aValue : aValue - bValue;
      });
    }

    // Actualizar el estado con las filas filtradas y ordenadas
    setFilteredRows(filtered);
  };

  // Llamamos a filterAndSortRows cada vez que cambian el filtro, la columna de ordenación, la página o la cantidad de filas por página
  useEffect(() => {
    filterAndSortRows();
  }, [filterText, sortColumn, sortDescending, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Función para manejar el clic en el encabezado de la columna
  const handleColumnHeaderClick = (column: string) => {
    if (sortColumn === column) {
      // Si ya está ordenado por esta columna, cambiar la dirección de ordenación
      setSortDescending(!sortDescending);
    } else {
      // Si es una columna diferente, ordenar ascendentemente
      setSortColumn(column);
      setSortDescending(false);
      setPage(0);
    }
  };

  return (
    <div className='h-full overflow-auto'>
      <Card style={{ border: 'solid', borderColor: 'grey', maxWidth: 1300 }}>
        <div>
          <TableContainer
            component={Paper}
            style={{ overflow: 'auto', maxHeight: 600 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ position: 'sticky', left: 0, zIndex: 1 }}
                  ></TableCell>
                  <TableCell
                    className='header-vendedor'
                    style={{ position: 'sticky', left: 50, zIndex: 1 }}
                  >
                    Cliente
                  </TableCell>
                  {allColumns.map((column) => (
                    <TableCell className='header-columna' key={column}>
                         <Checkbox
                        checked={dateSelected.includes(column)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onClick={() => {
                          if (dateSelected.includes(column)) {
                            setDateSelected([
                              ...dateSelected.filter((item) => item !== column),
                            ]);
                          } else {
                            setDateSelected([...dateSelected, column]);
                          }
                        }}
                      ></Checkbox>
                      {column}
                      <IconButton
                        size='small'
                        onClick={() => handleColumnHeaderClick(column)}
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        className='cell-columna'
                        style={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: 'white',
                        }}
                      >
                        <Checkbox
                          checked={selectedClients.includes(row.customer)}
                          onChange={() => handleClientSelection(row.customer!)}
                        />
                      </TableCell>
                      <TableCell
                        className='cell-columna'
                        style={{
                          position: 'sticky',
                          left: 50,
                          zIndex: 1,
                          backgroundColor: 'white',
                        }}
                      >
                        {row.customer}
                      </TableCell>
                      {allColumns.map((column) => (
                        <TableCell className='cell-columna' key={column}>
                          {typeof row[column] === 'number'
                            ? `$${formatChileanLocale(row[column] as number)}`
                            : '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    className='cell-columna'
                    style={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                      bottom: 0,
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    className='cell-columna'
                    style={{
                      position: 'sticky',
                      left: 50,
                      zIndex: 1,
                      backgroundColor: 'white',
                      bottom: 0,
                    }}
                  >
                    Totales
                  </TableCell>
                  {allColumns.map((column) => (
                    <TableCell
                      className='cell-columna'
                      key={column}
                      style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                      }}
                    >
                      {`$${formatChileanLocale(totals[column])}`}
                    </TableCell>
                  ))}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Card>
    </div>
  );
};

export default SalesTable;
