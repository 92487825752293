import { DataGrid, GridCellParams, GridRenderCellParams, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, esES } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../../hooks/storeHooks';
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { Button, Card } from '@mui/material';
import { fetchOrders } from '../../../../services/orderService';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

export const OrdersReportView = () => {
    const [sellersReports, setSellersReports] = useState([])
    const { sellers, isLoading } = useAppSelector((s: any) => s.sellerReducer);
    const { orders, isLoading: isLoadingOrders } = useAppSelector((s: any) => s.orderReducer);
    const effectRan = useRef(false);

    const [totalSales, settotalSales] = useState({
        name: "Total",
        monthSales: 0,
        daySales: 0,
    })

    useEffect(() => {
        if (effectRan.current === false) {
            const sellersWithSales = sellers.map((seller: Seller) => {
                const monthSales = orders.filter((order: Order) =>
                    order.seller === seller._id &&
                    checkSameMonth(new Date(), new Date(order.date)) &&
                    order.deleted === false
                ).reduce((acc: any, order: any) => acc + order.discountedNetTotal, 0);
                const daySales = orders.filter((order: Order) =>
                    order.seller === seller._id &&
                    checkSameDay(new Date(), new Date(order.date)) &&
                    order.deleted === false
                ).reduce((acc: any, order: any) => acc + order.discountedNetTotal, 0);
                return {
                    _id: seller._id,
                    name: seller.name,
                    monthSales,
                    daySales
                };
            });
            setSellersReports(sellersWithSales);
            return () => {
                effectRan.current = true;
            };
        }
    }, []);

    useEffect(() => {
        const totalMonthSales = sellersReports.reduce((acc: any, order: any) => acc + order.monthSales, 0);
        const totalDaySales = sellersReports.reduce((acc: any, order: any) => acc + order.daySales, 0);
        settotalSales({
            monthSales: totalMonthSales,
            daySales: totalDaySales,
            name: "Total"
        })
    }, [sellersReports]);

    const columns = [
        { field: 'name', headerName: 'Nombre Vendedor', width: 150 },
        {
            field: 'monthSales', headerName: 'Ventas del mes', width: 150,
            renderCell(params: GridRenderCellParams) {
                return params.row.monthSales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            }
        },
        {
            field: 'daySales', headerName: 'Ventas del día', width: 150,
            renderCell(params: GridRenderCellParams) {
                return params.row.daySales.toLocaleString("es-CL", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });
            }
        },
    ];

    return (
        <div className="h-full overflow-auto">
            {isLoading | isLoadingOrders ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <Card>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ margin: 2 }}
                        onClick={() => {
                            fetchOrders()
                        }}
                    >
                        Actualizar
                    </Button>
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        pageSize={1}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableColumnMenu
                        columns={columns}
                        rows={[totalSales]}
                        getRowId={(row) => row.name}
                        components={{ Toolbar: CustomToolbar }}
                    />
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        pageSize={20}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        disableColumnMenu
                        columns={columns}
                        rows={sellersReports}
                        getRowId={(row) => row._id}
                        components={{ Toolbar: CustomToolbar }}
                    />
                </Card>
            )}
        </div>
    )
}

const checkSameMonth = (first: Date, second: Date): Boolean =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth();

const checkSameDay = (first: Date, second: Date): Boolean =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
