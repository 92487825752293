import React, { useEffect } from 'react'
import { MarginPerFamilyTable } from './Tables/MarginPerFamilyTable'
import Icon from '@mdi/react';
import { mdiCircleSlice1 } from '@mdi/js';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import { fetchMarginSupplierMonth, fetchMarginFamilyMonth, fetchMarginBrandMonth } from '../../../../../services/statisticsService';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { MarginPerSupplierTable } from './Tables/MarginPerSupplierTable';
import { MarginPerBrandTable } from './Tables/MarginPerBrandTable';
import FilterInput from './FilterInput';

export const MarginsView = () => {
  const { statistics, isLoading } = useAppSelector((s: any) => s.statisticsReducer);
  const [tab, setTab] = React.useState<
    "Por Familia" |
    "Por Proveedor" |
    "Por Marca"
  >("Por Familia");
  const [filterText, setFilterText] = React.useState('');

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={(_, v) => {
            setTab(v as
              "Por Familia" |
              "Por Proveedor" |
              "Por Marca");
          }}
          aria-label="basic tabs example"
        >
          <Tab label="Por Familia" value={"Por Familia"} onClick={async () => {
            setFilterText('');
            return await fetchMarginFamilyMonth()
          }} disabled={isLoading} />
          <Tab label="Por Proveedor" value={"Por Proveedor"} onClick={async () => {
            setFilterText('');
            return await fetchMarginSupplierMonth()
          }} disabled={isLoading} />
          <Tab label="Por Marca" value={"Por Marca"} onClick={async () => {
            setFilterText('');
            return await fetchMarginBrandMonth()
          }} disabled={isLoading} />
        </Tabs>
      </Box>
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
            Por favor espere...
          </div>) :
          (
            <div className="flex-1 overflow-auto" style={{ paddingTop: '5px' }}>
              <FilterInput
                onFilterChange={setFilterText}
              />
              <Grid container direction={"row"} justifyContent={"center"} alignItems={"center"}>
                {tab === "Por Familia" && <MarginPerFamilyTable
                  data={statistics}
                  filterText={filterText}
                />}
                {tab === "Por Proveedor" && <MarginPerSupplierTable
                  data={statistics}
                  filterText={filterText}
                />}
                {tab === "Por Marca" && <MarginPerBrandTable
                  data={statistics}
                  filterText={filterText}
                />}
              </Grid>
            </div>
          )
        }
      </div>
    </div>
  )
}
