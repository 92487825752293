import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getSellerDeviceInfo } from "../../../../services/sellerService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    onSubmit: (values: { id: string; deviceQty: number }) => void;
    seller: Seller | null;
}

export const ManageDeviceModal: React.FC<Props> = ({
    open,
    onClose,
    onSubmit,
    seller
}) => {
    const [deviceInfo, setDeviceInfo] = useState<{
        deviceQty: number;
        activeDevices: number;
    }>({
        deviceQty: 1,
        activeDevices: 0,
    });

    const [numDevices, setNumDevices] = useState(1);

    const [isLoadingDeviceInfo, setIsLoadingDeviceInfo] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    useEffect(() => {
        setIsLoadingDeviceInfo(true);
        if (seller) {
            getSellerDeviceInfo(seller._id)
                .then((res) => {
                    if (res) {
                        setDeviceInfo({
                            deviceQty: res.deviceQty,
                            activeDevices: res.activeDevices,
                        })
                    }
                    else {
                        setErrorAlert(true);
                    }
                })
                .finally(() => {
                    setIsLoadingDeviceInfo(false);
                });
        }
    }, [seller, onSubmit]);



    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDialog-paper": {
                    width: "30rem",
                },
            }}
        >
            <DialogTitle>Administrar dispositivos</DialogTitle>
            {isLoadingDeviceInfo ? (
                <DialogContent>
                    <div className="flex flex-col gap-2 mt-5">
                        <div>Cargando...</div>
                    </div>
                </DialogContent>
            ) : (
                (errorAlert ? (
                    <DialogContent>
                        <div className="flex flex-col gap-2 mt-5">
                            <div>Error al cargar información</div>
                        </div>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <div>
                            <div style={{ fontWeight: 600 }}>{seller?.name}</div>
                            <List>
                                <ListItem key="active">Dispositivos activos: {deviceInfo.activeDevices}</ListItem>
                                <ListItem key="total">Dispositivos totales: {deviceInfo.deviceQty}</ListItem>
                            </List >
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <TextField
                                type="number"
                                label="Cantidad de dispositivos"
                                value={numDevices}
                                onChange={(event) => setNumDevices(parseInt(event.target.value))}
                            />
                        </div>
                        <div>
                            <div style={{ fontWeight: 600, color: "red" }}>Una vez reinicializado, el vendedor tendrá una única oportunidad de iniciar sesión por cada dispositivo disponible.</div>
                        </div>
                    </DialogContent>
                ))
            )}
            <DialogActions>
                <Button onClick={() => {
                    setNumDevices(1);
                    onClose();
                }}>Salir</Button>
                <Button variant="contained" onClick={() => {
                    onSubmit({ id: seller?._id || "", deviceQty: numDevices })
                    setNumDevices(1)
                }}>
                    Reiniciar Dispositivos
                </Button>
            </DialogActions>
        </Dialog>
    );
}