import React, { useState } from 'react'
import { GridRow, SalesPerDate, SalesPerFamily, SalesPerSupplier } from '../../../../../../types/Statistics';
import { Card, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { formatChileanLocale } from '../../../../../../utils/formatChileanLocale';

const transformData = (data: SalesPerSupplier[]): GridRow[] => {
    return data.map((item, index) => ({
        id: index,
        supplier: item.supplier,
        ...item.margin
    }));
};

const getAllColumns = (data: SalesPerSupplier[]): string[] => {
    const allColumns: Set<string> = new Set();
    console.log(data)
    data.forEach(item => {
        Object.keys(item.margin!).forEach(column => {
            allColumns.add(column);
        });
    });
    return Array.from(allColumns).sort();
};
export const MarginPerSupplierTable: React.FC<{
    data: SalesPerSupplier[],
    filterText: string,
}> = ({
    data,
    filterText
}) => {
        const [rows, setRows] = useState<GridRow[]>([]);
        const [allColumns, setAllColumns] = useState<string[]>([]);
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const [filteredRows, setFilteredRows] = useState<GridRow[]>([]);
        const handleChangePage = (event: unknown, newPage: number) => {
            setPage(newPage);
        };
        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };
        React.useEffect(() => {
            setRows(transformData(data));
            setAllColumns(getAllColumns(data));
        }, [data]);
        React.useEffect(() => {
            setFilteredRows(rows.filter(row => row.supplier?.toLowerCase().includes(filterText.toLowerCase())))
        }, [filterText, rows]);
        return (
            <div >
                <Card style={{ border: "solid", borderColor: "grey", maxWidth: 1500 }}  >
                    <div>
                        <TableContainer component={Paper} style={{ overflow: 'auto', maxHeight: 800 }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="header-vendedor" style={{ position: 'sticky', left: 0, zIndex: 1 }}>Proveedor</TableCell>
                                        {allColumns.map((column) => (
                                            <TableCell key={column}>{column}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell className='cell-columna' style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white' }}>{row.supplier}</TableCell>
                                            {allColumns.map((column) => {
                                                return <TableCell className='cell-columna' key={column}>{typeof row[column] === 'number' ? `${formatChileanLocale(row[column] as number * 100)}%` : '-'}</TableCell>;
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Card>
            </div>
        )
    }
