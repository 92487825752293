import {
    DataGrid,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridRenderCellParams,
} from "@mui/x-data-grid";
import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
        </GridToolbarContainer>
    );
}

const SellerDayReportView: React.FC = () => {
    const { sellers, isLoading } = useAppSelector((s: any) => s.sellerReducer);
    const { orders } = useAppSelector((s: any) => s.orderReducer);

    const [daySales, setDaySales] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));

    useEffect(() => {
        const dateSales = sellers.map((seller: Seller) => {
            const sales = orders.filter((order: Order) => {
                return (
                    order.seller === seller._id &&
                    dayjs(order.date).isSame(selectedDate, "day") &&
                    dayjs(order.date).isSame(selectedDate, "month") &&
                    dayjs(order.date).isSame(selectedDate, "year")
                );
            });
            return {
                ...seller,
                OrdersQTY: sales.length,
                sales: sales.reduce((acc: any, order: any) => acc + order.discountedNetTotal, 0),
            };
        });
        setDaySales(dateSales);
    }, [selectedDate]);

    return (
        <div className="h-full overflow-auto">
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <Card
                    className="p-5"
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{
                                marginBottom: 2,
                                width: 300,
                            }}
                            label="Fecha"
                            format="DD/MM/YYYY"
                            value={selectedDate}
                            onChange={(newValue) => {
                                setSelectedDate(newValue);
                            }}
                        />
                    </LocalizationProvider>
                    <DataGrid
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        columns={
                            [
                                {
                                    field: "name",
                                    headerName: "Vendedor",
                                    width: 150,
                                },
                                {
                                    field: "OrdersQty",
                                    headerName: "N° Pedidos",
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.OrdersQTY;
                                    },
                                },
                                {
                                    field: "sales",
                                    headerName: "Total",
                                    width: 100,
                                    renderCell(params: GridRenderCellParams) {
                                        return params.row.sales.toLocaleString("es-CL", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                        });
                                    },
                                },
                            ]}
                        rows={daySales}
                        pageSize={20}
                        getRowId={(row) => row._id}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{ Toolbar: CustomToolbar }}
                    />
                </Card>
            )}
        </div>
    );
};

export default SellerDayReportView;
