import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material";
export const isString = (item: any): item is string => {
  return typeof item === "string";
};

type AutoCompleteFieldProps<T> = {
  selectValue: keyof T;
  secondarySelectValue?: keyof T;
  options: T[];
  label: string;
  onChange: (value: T) => void;
  value: T;
};

const ComboBox = <T extends {}>({
  selectValue,
  secondarySelectValue,
  options,
  label,
  onChange,
  value,
}: AutoCompleteFieldProps<T>): React.ReactElement => {
  const filterOptions = createFilterOptions({
    limit: 500,
    matchFrom: "any",
    stringify: (option: T) => {
      const primaryValue = option[selectValue] ?? ""; // Asume string y maneja nulos
      const secondaryValue = secondarySelectValue ? (option[secondarySelectValue] ?? "") : "";
      return `${primaryValue}${secondaryValue}`;
    },
  });

  return (
    <Autocomplete<T>
      fullWidth={true}
      filterOptions={filterOptions}
      value={value}
      options={options}
      getOptionLabel={(option: any) =>
        (option[selectValue] ?? "").toString()
      }
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <>
              {option[selectValue]}&nbsp;&nbsp;&nbsp;
              {secondarySelectValue ? option[secondarySelectValue] : ""}
            </>
          </li>
        );
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(event, newValue) => {
        onChange(newValue as T);
      }}
    />
  );
};
export default ComboBox;
