import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Alert, Button, Card } from "@mui/material";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import {
  getRowsToArray,
  returnExistingProducts,
} from "../../../../utils/readExcel";
import { upsertProducts } from "../../../../services/productService";
import store from "../../../../store";
import { ProductActions } from "../../../../reducers/ProductReducer";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const StockView: React.FC = () => {
  const { isLoading, products, errorProducts, errorMsg, success } =
    useAppSelector((state: any) => state.productReducer);
  const [errorAlert, setErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const columns: GridColDef[] = [
    { field: "code", minWidth: 150, flex: 1, headerName: "Codigo" },
    { field: "description", minWidth: 350, flex: 1, headerName: "Descripcion" },
    { field: "brand", minWidth: 150, flex: 1, headerName: "Marca" },
    {
      field: "stockTotal",
      minWidth: 150,
      flex: 1,
      headerName: "Stock Total",
    }
  ];

  function handleUpload(
    e: ChangeEvent<HTMLInputElement>,
    typeOfUpload: string
  ): void {
    const file = e.target.files![0];
    console.log(typeOfUpload);
    getRowsToArray(file, typeOfUpload)
      .then((rows: any[]) => {
        const productsToSend = returnExistingProducts(products, rows);
        upsertProducts(productsToSend);

        e.target.value = "";
      })
      .catch((err: any) => {
        store.dispatch({
          type: ProductActions.UpdateErrorMsg,
          payload: "Error al leer el archivo",
        });
        setErrorAlert(true);

        e.target.value = "";
      });
  }

  useEffect(() => {
    if (errorProducts.length > 0) {
      setErrorAlert(true);
    } else if (errorMsg !== "") {
      setErrorAlert(true);
    }
  }, [errorProducts, errorMsg]);
  useEffect(() => {
    if (success) {
      setShowSuccessAlert(true);
    }
  }, [success]);
  return (
    <div className="h-full overflow-auto">
      {errorAlert && (
        <Alert
          severity="error"
          onClose={() => {
            setErrorAlert(false);
          }}
        >
          {errorMsg !== "" ? errorMsg : "Hay un error en las filas"}{" "}
          {errorProducts.map((error: { row: number }) => {
            return error.row + ", ";
          })}
        </Alert>
      )}
      {showSuccessAlert && (
        <Alert
          severity="success"
          onClose={() => {
            setShowSuccessAlert(false);
          }}
        >
          Se han subido los productos correctamente
        </Alert>
      )}

      <div className=" mt-2 mb-3 flex justify-end pr-7 space-x-2">
        <input
          accept=".xls, .xlsx"
          style={{ display: "none" }}
          id="contained-button-file-price"
          type="file"
          onChange={(e) => handleUpload(e, "Stock")}
        />
        <label htmlFor="contained-button-file-price">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            size="large"
          >
            Carga Masiva Stock
          </Button>
        </label>
      </div>
      <div>
        {isLoading ? (
          <div className="flex justify-center w-full col-span-full">
            <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
          </div>
        ) : (
          <Card>
            <DataGrid
              sx={{ padding: 2 }}
              autoHeight={true}
              columns={columns}
              rows={products}
              pageSize={20}
              getRowId={(row) => row._id}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default StockView;
