import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useForm } from "../../../../hooks/useForm";
import { addTypist, updateTypist } from "../../../../services/typistService";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/storeHooks";
import { fetchSellers } from "../../../../services/sellerService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    typist: Typist | null;
    setTypist: (typist: Typist | null) => void;
}

const NewTypistModal: React.FC<Props> = ({
    open,
    onClose,
    typist,
    setTypist,
}) => {
    const initialValues = {
        name: "",
        username: "",
        subordinates: [] as string[],
    };
    const [values, setValues, handleChange] = useForm(initialValues);
    const sellers = useAppSelector((state: any) => state.sellerReducer.sellers); //esto es para el modal
    useEffect(() => {
        if (typist) {
            setValues({
                ...values,
                name: typist.name,
                username: typist.username,
                subordinates: typist.subordinates,
            });
        } else {
            setValues(initialValues);
        }
    }, [typist]);

    useEffect(() => {
        fetchSellers();
    }, []);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onTransitionEnd={() => !open && setValues(initialValues)} //para borrar los values solo cuando se cierra
        >
            <DialogTitle>Nuevo Digitador</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <TextField
                        disabled={!!typist} //si hay un seller, es porque estoy editando, y no quiero que se pueda editar el nombre
                        onChange={handleChange}
                        id="username"
                        label="Usuario"
                        value={values.username}
                    ></TextField>
                    <TextField
                        onChange={handleChange}
                        id="name"
                        label="Nombre"
                        value={values.name}
                    ></TextField>
                    <FormControl>
                        <InputLabel id="priceList-label">Vendedores</InputLabel>
                        <Select
                            labelId="priceList-label"
                            id="priceList"
                            multiple
                            value={values.subordinates}
                            label="Lista de Precio"
                            onChange={(e) => {
                                setValues({ ...values, subordinates: e.target.value as string[] });
                            }}
                        >
                            {sellers.map((seller: Seller) => (
                                <MenuItem key={seller._id} value={seller._id}>
                                    {seller.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (typist !== null) {
                            setTypist(null);
                            setValues(initialValues);
                        }
                        onClose();
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    disabled={
                        values.username === "" ||
                        values.name === "" ||
                        values.subordinates.length === 0
                    }
                    onClick={async () => {
                        if (typist) {
                            await updateTypist(typist._id, values);
                            setTypist(null);
                            onClose();
                        } else {
                            await addTypist(values);
                            setTypist(null);
                            onClose();
                        }
                    }}
                >
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NewTypistModal;
